import { useCallback, useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { Link, useParams } from 'react-router-dom'
import withReactContent from 'sweetalert2-react-content'
import { mask_cnpj } from 'helpers/mask.helper'
import ToggleButton from 'react-toggle-button'
import Pagination from 'pagination-front-end'

import './index.scss'

import { Button } from 'components/button'
import Constants from '../../../../../../constants'
import { PageHeader } from 'components/page-header'
import { ProjectContainer } from 'components/project-container'
import { PaginationCustom } from '../../../../../../components/pagination-custom'

import { Customer } from 'interfaces/customer'
import CustomerService from 'services/CustomerService'
import { Representative } from 'interfaces/representative'
import RepresentativesService from 'services/RepresentativesService'
import { PageHeaderV2 } from 'components/pageHeaderV2'
import { BiLeftArrowAlt } from 'react-icons/bi'
import ButtonV3 from 'components/Buttonv3'
import { PhoneWithWhats } from 'components/phoneWithWhats'
import { RepresentativesServiceV3 } from 'services/v3/RepresentativesService'
import RepresentativeService from 'services/RepresentativesService'
import { ClientsRepresentative } from 'interfaces/clientsRepresentative'
import { FeedbackError } from 'components/feedback/error'
import { BaseModal } from 'components/BaseModal'
import { SellerParticipationDetailsModalV2 } from '../components/SellerParticipationDetailsModalV2'

export const RepresentativeClients = () => {
    const MySwal = withReactContent(Swal)

    const { id } = useParams<string>()
    const [loading, setLoading] = useState<boolean>(true)
    const [clients, setClients] = useState<ClientsRepresentative[]>([])
    const [seller, setSeller] = useState<Representative>()
    const [params, setParams] = useState({
        vendedor_id: id,
        attending: true,
        page: 1,
    })
    const [pagination, setPagination] = useState({
        current_page: 1,
        total: 0,
        per_page: 10,
        last_page: 0,
    })
    const [
        show_seller_participation_details,
        setShowSellerParticipationDetails,
    ] = useState<boolean>(false)
    const [currentRequest, setCurrentRequest] =
        useState<ClientsRepresentative>()

    const get_seller = useCallback(
        () =>
            RepresentativesService.getById(id as string).then(
                (response: Representative) => setSeller(response)
            ),
        [id]
    )

    useEffect(() => {
        get_seller()
    }, [get_seller, id])

    useEffect(() => {
        async function fetchClients() {
            setLoading(true)
            try {
                const response =
                    await RepresentativesServiceV3.getClientsRepresentative({
                        params,
                    })
                setClients(response.data)
                setPagination(response.meta)
            } catch (error) {
                console.error('Erro ao buscar clientes:', error)
            } finally {
                setLoading(false)
            }
        }
        fetchClients()
    }, [id, params])

    const changeStatus = (client: ClientsRepresentative) => {
        MySwal.fire({
            title: (
                <div>
                    <p>Você irá desassociar este cliente. Deseja continuar?</p>
                    <hr />
                </div>
            ),
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            cancelButtonColor: 'red',
        }).then((res) => {
            if (res.isConfirmed) {
                CustomerService.associate(
                    parseInt(id as string),
                    client.id as string,
                    client.status
                )
                    .then(() => {
                        setClients((prevClients) =>
                            prevClients.filter((c) => c.id !== client.id)
                        )

                        MySwal.fire(<p>Cliente desassociado com sucesso!</p>)
                    })
                    .catch((error) => {
                        console.error('Erro ao desassociar cliente:', error)
                        MySwal.fire(
                            <p>
                                Ocorreu um erro ao desassociar o cliente. Tente
                                novamente.
                            </p>
                        )
                    })
            }
        })
    }

    const get_participation_class = (participation) => {
        if (participation <= 0.3) {
            return 'low'
        } else if (participation > 0.7) {
            return 'high'
        } else {
            return 'medium'
        }
    }

    function renderParticipationValue(sellers, sellerId) {
        const seller = sellers.find((seller) => String(seller.id) === sellerId)
        return seller?.pivot?.participation ?? 0 // Retorna 0 caso não exista
    }

    function renderParticipation(sellers, sellerId) {
        const participation = renderParticipationValue(sellers, sellerId)

        if (participation !== undefined) {
            return (
                <span
                    className={[
                        'div_participation',
                        get_participation_class(participation),
                    ].join(' ')}
                >
                    {(participation * 100).toFixed(2)}%
                </span>
            )
        }

        return null
    }

    const open_participation_details = (item) => {
        setCurrentRequest(item)
        setShowSellerParticipationDetails(true)
    }

    return (
        <ProjectContainer loading={loading}>
            <div className="representative-clients">
                <header
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 40,
                    }}
                >
                    <div>
                        <PageHeaderV2 title="Clientes" />
                    </div>
                    <div style={{ marginRight: 20 }}>
                        <Link to={`${Constants.ROUTE_PROVIDER_EDIT}?r=1`}>
                            <ButtonV3
                                title="Voltar"
                                Icon={
                                    <BiLeftArrowAlt className="iconButtonV3"></BiLeftArrowAlt>
                                }
                            ></ButtonV3>
                        </Link>
                    </div>
                </header>
                <div className="col">
                    {seller && (
                        <div className="rowRepresentante">
                            {clients && (
                                <div className="repInfoDiv">
                                    <div>
                                        <h6>Quantidade Clientes:</h6>
                                    </div>
                                    <div>
                                        <p>{seller.count_vendedor}</p>
                                    </div>
                                </div>
                            )}
                            <div className="repInfoDiv">
                                <div>
                                    <h6>Nome:</h6>
                                </div>
                                <div>
                                    <p> {seller.name}</p>
                                </div>
                            </div>
                            <div className="repInfoDiv">
                                <div>
                                    <h6>Login:</h6>
                                </div>
                                <div>
                                    <p> {seller.login}</p>
                                </div>
                            </div>
                            <div className="repInfoDiv">
                                <div>
                                    <h6>Email:</h6>
                                </div>
                                <div>
                                    <p> {seller.email}</p>
                                </div>
                            </div>
                            <div className="repInfoDiv">
                                <div>
                                    <h6>Telefone:</h6>
                                </div>
                                <div>
                                    <PhoneWithWhats phone={seller.phone} />
                                </div>
                            </div>
                        </div>
                    )}

                    {clients.length > 0 ? (
                        <div className="newListCLientsRepresentatives">
                            <table
                                style={{ marginBottom: 30 }}
                                className="newTable"
                            >
                                <thead>
                                    <tr>
                                        <th>Cliente</th>
                                        <th>CNPJ</th>
                                        <th>Estado/Cidade</th>
                                        {/* <th>Cidade</th> */}
                                        <th>Segmento</th>
                                        <th>Contato</th>
                                        <th className="text-center">
                                            Telefone
                                        </th>
                                        <th className="text-center">
                                            Participação
                                        </th>
                                        <th className="text-center">
                                            Ativar / Desativar
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {clients &&
                                        clients.map(
                                            (item: ClientsRepresentative) => (
                                                <tr key={item.id}>
                                                    <td data-label="Cliente">
                                                        {item.name}
                                                    </td>
                                                    <td data-label="CNPJ">
                                                        {item.cnpj
                                                            ? mask_cnpj(
                                                                  item.cnpj
                                                              )
                                                            : ''}
                                                    </td>
                                                    <td data-label="Estado/Cidade">
                                                        <div
                                                            style={{
                                                                marginBottom: 3,
                                                            }}
                                                        >
                                                            {item.head_office
                                                                ?.state?.name ||
                                                                ''}
                                                        </div>
                                                        <div>
                                                            {item.head_office
                                                                ?.city.name ||
                                                                ''}
                                                        </div>
                                                    </td>
                                                    {/* <td data-label="Cidade">
                                                        {item.head_office?.city
                                                            .name || ''}
                                                    </td> */}
                                                    <td data-label="Segmento">
                                                        {item.sectors?.[0]
                                                            ?.name || ''}
                                                    </td>
                                                    <td data-label="Nome Contato">
                                                        {item.contact
                                                            ? item.contact.split(
                                                                  ' '
                                                              ).length === 1
                                                                ? item.contact
                                                                      .charAt(0)
                                                                      .toUpperCase() +
                                                                  item.contact.slice(
                                                                      1
                                                                  )
                                                                : item.contact
                                                                      .split(
                                                                          ' '
                                                                      )
                                                                      .slice(
                                                                          1,
                                                                          3
                                                                      )
                                                                      .map(
                                                                          (
                                                                              word,
                                                                              index
                                                                          ) =>
                                                                              index ===
                                                                              0
                                                                                  ? word
                                                                                        .charAt(
                                                                                            0
                                                                                        )
                                                                                        .toUpperCase() +
                                                                                    word.slice(
                                                                                        1
                                                                                    )
                                                                                  : word
                                                                      )
                                                                      .join(' ')
                                                            : 'N/A'}
                                                    </td>
                                                    <td
                                                        data-label="Telefone"
                                                        className="td_telefone"
                                                    >
                                                        <div>
                                                            <PhoneWithWhats
                                                                phone={
                                                                    item.phone ||
                                                                    ''
                                                                }
                                                            />
                                                        </div>
                                                    </td>
                                                    <td
                                                        data-label="Participação"
                                                        className="td_Participacao"
                                                    >
                                                        <div
                                                            onClick={() => {
                                                                if (
                                                                    renderParticipationValue(
                                                                        item.sellers,
                                                                        id
                                                                    ) !== 0
                                                                ) {
                                                                    open_participation_details(
                                                                        item
                                                                    )
                                                                }
                                                            }}
                                                            title={
                                                                renderParticipationValue(
                                                                    item.sellers,
                                                                    id
                                                                ) === 0
                                                                    ? 'Não possui histórico de participação'
                                                                    : 'Ver histórico de participação'
                                                            }
                                                            style={{
                                                                cursor:
                                                                    renderParticipationValue(
                                                                        item.sellers,
                                                                        id
                                                                    ) === 0
                                                                        ? 'not-allowed'
                                                                        : 'pointer',
                                                            }}
                                                        >
                                                            {renderParticipation(
                                                                item.sellers,
                                                                id
                                                            )}
                                                        </div>
                                                    </td>

                                                    <td
                                                        data-label="Ativar/Desativar"
                                                        className="text-center"
                                                    >
                                                        <div
                                                            title="Desassociar este cliente"
                                                            className={
                                                                'tooltip-icon'
                                                            }
                                                        >
                                                            <ToggleButton
                                                                inactiveLabel={
                                                                    ''
                                                                }
                                                                activeLabel={
                                                                    null
                                                                }
                                                                colors={{
                                                                    activeThumb:
                                                                        {
                                                                            base: 'white',
                                                                        },
                                                                    inactiveThumb:
                                                                        {
                                                                            base: 'white',
                                                                        },
                                                                    active: {
                                                                        base: '#FF7110',
                                                                        hover: '#FF7110',
                                                                    },
                                                                    inactive: {
                                                                        base: '#e2e2e2',
                                                                        hover: '#d9d9d9',
                                                                    },
                                                                }}
                                                                value={
                                                                    item.status
                                                                }
                                                                onToggle={() => {
                                                                    changeStatus(
                                                                        item
                                                                    )
                                                                }}
                                                            />

                                                            {
                                                                //   <span
                                                                //       className={'tooltiptext'}>
                                                                //       {'Desassociar este cliente'}
                                                                //   </span>
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                </tbody>
                            </table>
                            <PaginationCustom
                                total={
                                    pagination?.last_page
                                        ? Math.max(1, pagination.last_page - 1)
                                        : 1
                                }
                                currentPage={pagination?.current_page || 1}
                                onPageChange={(newPage) =>
                                    setParams((prev) => ({
                                        ...prev,
                                        page: newPage,
                                    }))
                                }
                                totalItems={pagination?.total}
                                ItemsPorPage={pagination?.per_page}
                                itemsName={'clientes'}
                            />
                        </div>
                    ) : (
                        <div style={{ marginTop: 15 }}>
                            <FeedbackError message="Nenhum cliente associados foi encontrado no momento." />
                        </div>
                    )}
                </div>
            </div>
            {show_seller_participation_details && (
                <BaseModal
                    modalBodyStyle={{ height: 'unset' }}
                    title={`#${seller?.name} - ${currentRequest?.group_name}`}
                    isVisible={show_seller_participation_details}
                    onCloseCallback={() =>
                        setShowSellerParticipationDetails(false)
                    }
                    children={
                        <SellerParticipationDetailsModalV2
                            request={currentRequest}
                            seller={seller}
                            setShowSellerParticipationDetails={
                                setShowSellerParticipationDetails
                            }
                        />
                    }
                />
            )}
        </ProjectContainer>
    )
}
