import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from 'react-router-dom'
import { Provider, useDispatch } from 'react-redux'
import { configureStore } from './store/configureStore'
import './styles/style.scss'
import DirectSaleOrderList from './pages/direct-sale-order'
import Products from './pages/products'
import { SignIn } from './pages/sign-in'
import { RequestAccess } from './pages/request-access'
import { ProviderRequestAccess } from './pages/request-access/provider.page'
import { AbraselBA } from './pages/request-access/abrasel-ba.page'
import ProtectedRoute from './protected-route'
import Constants from './constants'
import BuyingCompanies from './pages/buying-companies'
import BuyingCompanyAdd from './pages/buying-companies/add'
import BuyingCompanyAddSingle from './pages/buying-companies/add/add-avulso'
import ProductAdd from './pages/products/add'
import { ProductEdit } from './pages/products/edit'
import ProviderEdit from './pages/provider/edit'
import Orders from './pages/orders'
import RepresentativeAdd from './pages/representatives/add'
import CampaignsAdd from './pages/campaigns/components/add'
import Representative from './pages/representatives'
import { CampaignsPage } from './pages/campaigns'
import RepresentativeEdit from './pages/representatives/edit'
import RequestsDetailPage from './pages/request'
import RequestsPage from './pages/requests'
import Manager from './pages/request-graphs'
import SnackbarProvider from 'react-simple-snackbar'
import { AuthProvider } from 'contexts/user'
import 'bootstrap'
import { RepresentativeClients } from 'pages/provider/edit/components/representatives/clients'
import DashboardPage from 'pages/dashboard'
import { RequestProvider } from 'contexts/request'
import GroupsProduct from 'pages/products/groups'
import { ProductPriceLevels } from 'pages/products/price-level'
import { SalesReport } from 'pages/salesReport'
import DeliveryMonitoring from 'pages/deliveryMonitoring'
import { NotWinnersReport } from 'pages/notWinnersReport'
import { SindibaresGo } from 'pages/request-access/sindibares-go.page'
import { VrSystem } from 'pages/request-access/vrsystem.page'
import { WinnersReport } from 'pages/winnersReport'
import { setIsMobile } from 'store/Utility'
// import CustomersDirectOrder from 'pages/customers-direct-order'

const store = configureStore()

export function App() {
    const dispatch = useDispatch()

    useEffect(() => {
        function resize() {
            const isMobileView = window.innerWidth < 900
            dispatch(setIsMobile(isMobileView))
        }

        resize()
        window.addEventListener('resize', resize)

        return () => {
            window.removeEventListener('resize', resize)
        }
    }, [dispatch])

    return (
        <RequestProvider>
            <Routes>
                <Route path={Constants.ROUTE_SIGNIN} element={<SignIn />} />
                <Route
                    path={Constants.ROUTE_REQUEST_ACCESS}
                    element={<RequestAccess />}
                />
                <Route
                    path={Constants.ROUTE_REQUEST_ACCESS_PROVIDER}
                    element={<ProviderRequestAccess />}
                />
                <Route
                    path={Constants.ROUTE_ABRASEL_BH}
                    element={<AbraselBA />}
                />
                <Route
                    path={Constants.ROUTE_SINDIBARES_GO}
                    element={<SindibaresGo />}
                />
                <Route path={Constants.ROUTE_VRSYSTEM} element={<VrSystem />} />
                <Route path="/" element={<Navigate to="/cotacoes" />} />
                <Route
                    path={Constants.ROUTE_DASHBOARD}
                    element={<ProtectedRoute Component={DashboardPage} />}
                />
                <Route
                    path={Constants.ROUTE_MONITORING}
                    element={<ProtectedRoute Component={DeliveryMonitoring} />}
                />
                <Route
                    path={Constants.ROUTE_MANAGER_ANALITIC}
                    element={<ProtectedRoute Component={Manager} />}
                />
                <Route
                    path={Constants.ROUTE_REQUEST_DETAIL}
                    element={<ProtectedRoute Component={RequestsDetailPage} />}
                />
                <Route
                    path={`${Constants.ROUTE_REPRESENTATIVES_EDIT}/:id`}
                    element={<ProtectedRoute Component={RepresentativeEdit} />}
                />
                <Route
                    path={Constants.ROUTE_REPRESENTATIVES_ADD}
                    element={<ProtectedRoute Component={RepresentativeAdd} />}
                />
                <Route
                    path={Constants.ROUTE_PROVIDER_EDIT}
                    element={<ProtectedRoute Component={ProviderEdit} />}
                />
                <Route
                    path={Constants.ROUTE_REPRESENTATIVES}
                    element={<ProtectedRoute Component={Representative} />}
                />
                <Route
                    path={Constants.ROUTE_REQUESTS}
                    element={<ProtectedRoute Component={RequestsPage} />}
                />

                <Route
                    path={Constants.ROUTE_REPRESENTATIVES_CLIENTS}
                    element={
                        <ProtectedRoute Component={RepresentativeClients} />
                    }
                />
                <Route
                    path={Constants.ROUTE_ORDERS}
                    element={<ProtectedRoute Component={Orders} />}
                />
                <Route
                    path={Constants.ROUTE_CLIENTS}
                    element={<ProtectedRoute Component={BuyingCompanies} />}
                />
                {/* <Route
                    path={`${Constants.ROUTE_CLIENTS}/customer-direct`}
                    element={
                        <ProtectedRoute Component={CustomersDirectOrder} />
                    }
                /> */}
                <Route
                    path={`${Constants.ROUTE_CLIENTS}/register/:id`}
                    element={<ProtectedRoute Component={BuyingCompanyAdd} />}
                />
                <Route
                    path={Constants.ROUTE_PRODUCTS}
                    element={<ProtectedRoute Component={Products} />}
                />

                <Route
                    path={`${Constants.ROUTE_CLIENTS}/register`}
                    element={
                        <ProtectedRoute Component={BuyingCompanyAddSingle} />
                    }
                />

                <Route
                    path={Constants.ROUTE_PRODUCTS}
                    element={<ProtectedRoute Component={Products} />}
                />
                <Route
                    path={Constants.ROUTE_PRODUCTS_ADD}
                    element={<ProtectedRoute Component={ProductAdd} />}
                />
                <Route
                    path={`${Constants.ROUTE_PRODUCTS_EDIT}:id`}
                    element={<ProtectedRoute Component={ProductEdit} />}
                />
                <Route
                    path={`${Constants.ROUTE_PRODUCTS_EDIT_PRICE_LEVEL}:id`}
                    element={<ProtectedRoute Component={ProductPriceLevels} />}
                />
                <Route
                    path={Constants.ROUTE_GROUP_PRODUCTS}
                    element={<ProtectedRoute Component={GroupsProduct} />}
                />
                <Route
                    path={Constants.ROUTE_DIRECT_SALE_ORDER}
                    element={<ProtectedRoute Component={DirectSaleOrderList} />}
                />
                <Route
                    path={Constants.ROUTE_SALES_REPORT}
                    element={<ProtectedRoute Component={SalesReport} />}
                />
                <Route
                    path={Constants.ROUTE_NOT_WINNER_REPORT}
                    element={<ProtectedRoute Component={NotWinnersReport} />}
                />
                <Route
                    path={Constants.ROUTE_WINNER_REPORT}
                    element={<ProtectedRoute Component={WinnersReport} />}
                />
                <Route
                    path={Constants.ROUTE_CAMPAIGNS}
                    element={<ProtectedRoute Component={CampaignsPage} />}
                />
                <Route
                    path={Constants.ROUTE_CAMPAINS_ADD}
                    element={<ProtectedRoute Component={CampaignsAdd} />}
                />
                <Route
                    path={`${Constants.ROUTE_CAMPAIGNS_EDIT}:id`}
                    element={<ProtectedRoute Component={CampaignsAdd} />}
                />

                {/* <ProtectedRoute path={ Constants.ROUTE_MANAGER_ANALITIC } component={ Manager } />
                <ProtectedRoute path={ Constants.ROUTE_REQUEST_DETAIL }>
                    <RequestProvider>
                        <RequestsDetailPage />
                    </RequestProvider>
                </ProtectedRoute>
                <ProtectedRoute path={ `${Constants.ROUTE_REPRESENTATIVES_EDIT}/:id` } component={ RepresentativeEdit } />
                <ProtectedRoute path={ Constants.ROUTE_REPRESENTATIVES_ADD } component={ RepresentativeAdd } /> */}
                {/* <ProtectedRoute path={ Constants.ROUTE_PROVIDER_EDIT } component={ ProviderEdit } />
                <ProtectedRoute path={ Constants.ROUTE_REPRESENTATIVES } component={ Representative } />
                <ProtectedRoute path={ Constants.ROUTE_REPRESENTATIVES_CLIENTS } component={ RepresentativeClients } />
                <ProtectedRoute path={ Constants.ROUTE_ORDERS } component={ Orders } />
                <ProtectedRoute path={ `${Constants.ROUTE_CLIENTS}/register/:id` } component={ BuyingCompanyAdd } />
                <ProtectedRoute path={ Constants.ROUTE_CLIENTS } component={ BuyingCompanies } />
                <ProtectedRoute exact path={ Constants.ROUTE_PRODUCTS_ADD } component={ ProductAdd } />
                <ProtectedRoute exact path={ `${Constants.ROUTE_PRODUCTS_EDIT}:id` } component={ ProductEdit } />
                <ProtectedRoute exact path={ `${Constants.ROUTE_PRODUCTS_EDIT_PRICE_LEVEL}:id` } component={ ProductPriceLevels } />
                <ProtectedRoute exact path={ Constants.ROUTE_PRODUCTS } component={ Products } />
                <ProtectedRoute path={ Constants.ROUTE_GROUP_PRODUCTS } component={ GroupsProduct } />
                <ProtectedRoute path={ Constants.ROUTE_DIRECT_SALE_ORDER } component={ DirectSaleOrderList } />
                <ProtectedRoute path={ Constants.ROUTE_REQUESTS } component={ RequestsPage } />
                <ProtectedRoute path={ Constants.ROUTE_CAMPAIGNS } component={ CampaignsPage } />
                <ProtectedRoute path={ Constants.ROUTE_CAMPAINS_ADD } component={ CampaignsAdd } />
                <ProtectedRoute path={ `${Constants.ROUTE_CAMPAIGNS_EDIT}:id` } component={ CampaignsAdd } /> */}
            </Routes>
        </RequestProvider>
    )
}

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <SnackbarProvider>
                <AuthProvider>
                    <App />
                </AuthProvider>
            </SnackbarProvider>
        </Router>
    </Provider>,
    document.getElementById('root')
)
