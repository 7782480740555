import { useForm } from 'react-hook-form'
import { useSnackbar } from 'react-simple-snackbar'
import { Button } from 'components/button'
import { Input } from 'components/input'
import Constants from '../../../../../constants'
import { FeedbackError } from 'components/feedback/error'
import ClientServiceV2 from 'services/v2/ClientService'
import './index.scss'
import SnackBarSuccessStyle from 'styles/snackbar-success.style'
import { Snackbar } from 'components/snackbar'
import { Modal } from '../modal-generic'
import ToggleButton from 'react-toggle-button'
import { useState } from 'react'

interface ClientFormProps {
    onClose: () => void
    isVisible: boolean
    load_registers: () => void
}

const RegisterClientForm = ({
    onClose,
    isVisible,
    load_registers,
}: ClientFormProps) => {
    const { register, handleSubmit, errors } = useForm()
    const [openSnackbar] = useSnackbar(SnackBarSuccessStyle)
    const [isCpf, setIsCpf] = useState(true)
    const [cpf, setCpf] = useState<number | null>()
    const [errorMessage, setErrorMessage] = useState('')

    const save = async (data) => {
        try {
            await ClientServiceV2.createSigle(data)
            load_registers()
            setCpf(null)
            openSnackbar(
                <Snackbar icon="sticky_note_2">
                    Cadastro realizado com sucesso!
                </Snackbar>
            )
            setTimeout(() => {
                onClose()
            }, 1500)
        } catch (error: any) {
            console.error(error)
            if (error.response?.status === 400) {
                setErrorMessage(
                    `Já existe uma solicitação pendente com este ${
                        isCpf ? 'CPF' : 'CNPJ'
                    }.`
                )
            } else {
                openSnackbar(
                    <Snackbar icon="sticky_note_2">
                        Ocorreu um erro ao salvar o registro, tente novamente
                        mais tarde!
                    </Snackbar>
                )
            }
        }
    }

    const handleCancel = () => {
        setErrorMessage('')
        setCpf(null)
        onClose()
    }

    return (
        <Modal title="Cadastrar Cliente" isOpen={isVisible} onClose={onClose}>
            <section className="section-form-modal">
                <form onSubmit={handleSubmit(save)}>
                    <div
                        className="toggleButton"
                        style={{ marginBottom: '1vh' }}
                    >
                        <ToggleButton
                            inactiveLabel={
                                <p className="switchButtonLabel">CNPJ</p>
                            }
                            activeLabel={
                                <p className="switchButtonLabel">CPF</p>
                            }
                            value={isCpf}
                            onToggle={() => setIsCpf(!isCpf)}
                            colors={{
                                activeThumb: { base: 'white' },
                                inactiveThumb: { base: 'white' },
                                active: {
                                    base: '#FF7110',
                                    hover: '#FF7110',
                                },
                                inactive: {
                                    base: '#FF7110',
                                    hover: '#FF7110',
                                },
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <Input
                            type="text"
                            id="cnpj"
                            name="cnpj"
                            description="CPF / CNPJ"
                            placeholder="CPF / CNPJ"
                            register={register({ required: true })}
                            mask={
                                isCpf ? '999.999.999-99' : '99.999.999/9999-99'
                            }
                            value={cpf}
                            onChange={(e: any) => setCpf(e.target.value)}
                        />
                        {errors.cnpj && (
                            <FeedbackError
                                message={Constants.MSG_REQUIRED_FIELD}
                            />
                        )}
                        {errorMessage && (
                            <FeedbackError message={errorMessage} />
                        )}
                    </div>
                    {/* <div className="form-group">
                        <Input
                            type="text"
                            id="email"
                            name="email"
                            description="Email / Login"
                            placeholder="Email / Login"
                            register={register({
                                required: true,
                                pattern:
                                    /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                            })}
                        />
                        {errors.email && (
                            <FeedbackError
                                message={
                                    errors.email.type === 'required'
                                        ? Constants.MSG_REQUIRED_FIELD
                                        : Constants.MSG_PATTERN_FIELD
                                }
                            />
                        )}
                    </div>
                    <div className="form-group">
                        <Input
                            type="password"
                            id="password"
                            name="senha"
                            description="Senha"
                            placeholder="Senha"
                            register={register({ required: true })}
                        />
                        {errors.senha && (
                            <FeedbackError
                                message={Constants.MSG_REQUIRED_FIELD}
                            />
                        )}
                    </div>
                    <div className="form-group">
                        <Input
                            type="text"
                            id="contato"
                            name="contato"
                            description="Contato"
                            placeholder="Contato"
                            register={register({ required: true })}
                        />
                        {errors.contato && (
                            <FeedbackError
                                message={Constants.MSG_REQUIRED_FIELD}
                            />
                        )}
                    </div>
                    <div className="form-group">
                        <Input
                            type="text"
                            id="telefone"
                            name="telefone"
                            description="Telefone"
                            placeholder="Telefone"
                            register={register({ required: true })}
                            mask="(99) 99999-9999"
                        />
                        {errors.telefone && (
                            <FeedbackError
                                message={Constants.MSG_REQUIRED_FIELD}
                            />
                        )}
                    </div> */}

                    <div className="divButtons">
                        <Button type="submit" className="c-btn-success">
                            <i className="material-icons">save_alt</i>
                            Salvar
                        </Button>
                        <Button
                            type="button"
                            className="c-btn-cancel"
                            onClick={handleCancel}
                        >
                            Cancelar
                        </Button>
                    </div>
                </form>
            </section>
        </Modal>
    )
}

export default RegisterClientForm
