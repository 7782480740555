import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react'
import Constants from '../../constants'
import DateService from '../../services/DateService'
import DirectSaleOrderService from '../../services/DirectSaleOrderService'
import { connect, useSelector } from 'react-redux'
import { loadDirectSaleOrders } from '../../store/directSaleOrder/index'
import { loadCustomers } from '../../store/customer/index'
import { DirectSaleOrder } from '../../interfaces/directSaleOrder'
import { ProjectContainer } from '../../components/project-container/index'
import { Advise } from '../../components/advise'
import { Pagination } from '../../components/pagination'
import { FeedbackError } from '../../components/feedback/error'
import { FeedbackSuccess } from '../../components/feedback/success'
import { DirectSaleOrderPrint } from './components/print'
import { DirectSaleOrderModalDetails } from './components/modal-details'
import { DirectOrderDeliveryModal } from './components/direct-order-delivery-date'
import { Loader } from '../../components/loader'
import { PaginationCustom } from '../../components/pagination-custom'
import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import './index.scss'
import { Status, STATUS_WAITING_CONFIRMAION } from '../../components/status'
import { PageHeader } from '../../components/page-header'
import DirectOrdersFilterComponent from './components/filters'
import { Button } from '../../components/button'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { BiError, BiPrinter } from 'react-icons/bi'
import { AiOutlineCheckCircle, AiOutlineEye } from 'react-icons/ai'
import { BsCheckAll } from 'react-icons/bs'
import { useReactToPrint } from 'react-to-print'
import { FiCheckCircle, FiEdit } from 'react-icons/fi'
import { BiEditAlt } from 'react-icons/bi'
import ProviderService from 'services/ProviderService'
import { UserContext } from 'contexts/user'
import { EditDirectPurchaseModalComponent } from './components/edit-order'
import { PageHeaderV2 } from 'components/pageHeaderV2'
import { BaseModal } from 'components/BaseModal'
import { DirectSaleOrderDetails } from './components/direct-sale-order-details/direct-sale-order-details'
import ButtonV3 from 'components/Buttonv3'
import { SiMicrosoftexcel } from 'react-icons/si'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { ReactComponent as PixLogo } from '../../assets/pix.svg'
import { RoadMap } from 'components/roadmap'
import { HiOutlineTruck } from 'react-icons/hi'
import { MdPix } from 'react-icons/md'
import DownloadExcelModal from '../orders/components/downloadExcelModal'
import { FaChevronUp } from 'react-icons/fa'
import { FaChevronDown } from 'react-icons/fa6'
import { PaginationCustomV3 } from 'components/pagination-customerV3'

const DirectSaleOrderList = ({ directSaleOrders, findOrders }: any) => {
    const endDate = new Date()
    const initialDate = DateService.removeDaysToDate(new Date(endDate), 10)
    const page = 1
    const [showFilters, setShowFilters] = useState<boolean>(true)
    const [storeId, setStoreId] = useState<string | null>(null)
    const [error, setError] = useState<string>('')
    const [successMessage, setSuccessMessage] = useState<string>('')

    const isMobile = useSelector((state: any) => state.mobileReducer.isMobile)

    const initialQueryParams = {
        page: page,
        datainicial: DateService.formatToRequestWithTimezone(initialDate),
        // datafinal: DateService.formatToRequest(endDate),
        datafinal: null,
        status: null,
        seller_id: null,
        customerId: null,
        integration_field: null,
        status_control: null,
    }

    const [printOrder, setPrintOrder] = useState<DirectSaleOrder | null>(null)
    const [detailsOrder, setDetailsOrder] = useState<DirectSaleOrder | null>(
        null
    )
    const [editOrderModalIsVisible, setEditOrderModalIsVisible] =
        useState(Boolean)
    const [detailsOrderIsVisible, setDetailsOrderIsVisible] = useState(Boolean)

    const [queryParams, setQueryParams] = useState(initialQueryParams)
    const [loading, setLoading] = useState<boolean>(true)
    const [routes, setRoutes] = useState(null)
    const [locales, setLocales] = useState<any>(null)
    const { user, can } = useContext(UserContext)

    const MySwal = withReactContent(Swal)

    const search = useCallback(() => {
        if (user) {
            setLoading(true)
            const searchOrders = async () =>
                await findOrders(
                    can('READ_ALL', 'DIRECT_PURCHASE')
                        ? queryParams
                        : { ...queryParams, seller_id: user?.id }
                )
            searchOrders().then(() => {
                if (isMobile) {
                    setShowFilters(false)
                }
                setLoading(false)
            })
        }
    }, [findOrders, user, can, queryParams])

    const toogleFilters = () => {
        setShowFilters(!showFilters)
    }

    function handleSendFieldControl(id) {
        MySwal.fire({
            title: 'Deseja enviar novamente a integração ?',
            icon: 'warning',
            iconColor: '#ff7110',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
        }).then((result) => {
            if (result.isConfirmed) {
                DirectSaleOrderService.fieldControlPost(id).then((res) =>
                    setQueryParams({ ...queryParams })
                )
            }
        })
    }
    const goToPage = (nextPage: number) =>
        setQueryParams({ ...queryParams, page: nextPage })

    const confirm = (order: DirectSaleOrder) => {
        if (window.confirm(Constants.MSG_ORDER_CONFIRM_QUESTION)) {
            DirectSaleOrderService.confirm(order.id)
                .then(() => {
                    setSuccessMessage(Constants.MSG_ORDER_CONFIRM_SUCCESS)
                    search()
                    setTimeout(() => {
                        setSuccessMessage('')
                    }, 5000)
                })
                .catch(() => {
                    setError(Constants.MSG_ORDER_CONFIRM_FAIL)
                    setTimeout(() => {
                        setError('')
                    }, 5000)
                })
        }
    }

    const seeDetails = (item: DirectSaleOrder | null) => {
        setDetailsOrder(item)
        setDetailsOrderIsVisible(true)
    }

    const componentRef = useRef(null)

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    const print = async (item: DirectSaleOrder) => {
        await Promise.resolve().then(() => setPrintOrder(item))
        handlePrint && handlePrint()
    }

    const get_integration_classname = (integration) => {
        return integration
            ? 'text-center integrated'
            : 'text-center not-integrated'
    }

    useEffect(() => {
        user &&
            ProviderService.getProviderRoutes(user.providerId).then(
                (response) => setRoutes(response)
            )
        user &&
            ProviderService.getProviderRoutesLocales(user.providerId).then(
                (response) => setLocales(response)
            )
    }, [user])

    useEffect(() => {
        search()
    }, [queryParams, findOrders, user, search])

    useEffect(() => {
        setLoading(!directSaleOrders)
    }, [directSaleOrders])

    useEffect(() => {
        !isMobile && setShowFilters(true)
    }, [isMobile])

    function handleOpenModal(data) {
        setDetailsOrder(data)
        setEditOrderModalIsVisible(true)
    }

    function handleReturnLocalRetirada(id) {
        if (locales && id) {
            let local = locales.find((item) => item.id == id)
            if (local) {
                return local.descricao
            }
        }
        return false
    }

    function getPaymentStatusInfo(status, id) {
        let info
        switch (status) {
            case 'PAGO':
                info = (
                    <div style={{ width: 14, marginRight: 3 }}>
                        <PixLogo
                            title="PIX"
                            style={{
                                color: '#28BCAC',
                                filter: 'invert(61%) sepia(48%) saturate(600%) hue-rotate(124deg) brightness(92%) contrast(91%)',
                            }}
                            fontSize={2}
                        />{' '}
                        {id}
                    </div>
                )
                break
            case 'CANCELADO':
                info = (
                    <div style={{ width: 14, marginRight: 3 }}>
                        <PixLogo
                            title="CANCELADO"
                            style={{
                                color: 'red',
                                filter: 'invert(61%) sepia(48%) saturate(600%) hue-rotate(124deg) brightness(92%) contrast(91%)',
                            }}
                            fontSize={2}
                        />
                        {id}
                    </div>
                )
                break

            default:
                info = '#'
                break
        }
        return info
    }

    return (
        <ProjectContainer>
            <div className="direct-sale-order color-gray-2">
                <RoadMap
                    pageLinks={[
                        {
                            title: 'Pedidos venda direta',
                            onclick: () => {},
                        },
                    ]}
                ></RoadMap>
                <header
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 5,
                    }}
                >
                    <PageHeaderV2 title="Pedidos venda direta" />
                    {isMobile ? (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <ButtonV3
                                style={{
                                    justifyContent: 'space-between',
                                    height: 49,
                                }}
                                title={
                                    showFilters
                                        ? 'Ocultar filtros'
                                        : 'Exibir filtros'
                                }
                                textStyle={{ fontSize: 15 }}
                                onClick={toogleFilters}
                                Icon={
                                    showFilters ? (
                                        <FaChevronUp
                                            style={{ marginRight: 10 }}
                                            color="#ff7110"
                                        />
                                    ) : (
                                        <FaChevronDown
                                            style={{ marginRight: 10 }}
                                            color="#ff7110"
                                        />
                                    )
                                }
                            ></ButtonV3>
                        </div>
                    ) : null}
                </header>
                {
                    //  <Advise icon="label_important" message={Constants.MSG_DIRECT_SALE_ORDER_ADVISE} />
                }
                {
                    //   <div className="direct-list-actions">
                    //       <div className="actions">
                    //           <Button onClick={() => toogleFilters()}>
                    //               {showFilters ? (
                    //                   <span className="flex items-center justify-center">
                    //                       <i className="material-icons">close</i>
                    //                       Filtros
                    //                   </span>
                    //               ) : (
                    //                   <span className="flex items-center justify-center">
                    //                       <i className="material-icons">filter_alt</i>
                    //                       Filtros
                    //                   </span>
                    //               )}
                    //           </Button>
                    //       </div>
                    //   </div>
                }

                {
                    // showFilters ? <DirectOrdersFilterComponent onChange={setQueryParams} /> : null
                }

                {showFilters ? (
                    <DirectOrdersFilterComponent
                        locales={locales}
                        onChange={setQueryParams}
                    />
                ) : null}

                {error && <FeedbackError message={error} />}

                {successMessage && <FeedbackSuccess message={successMessage} />}

                {!loading ? (
                    directSaleOrders && directSaleOrders.total > 0 ? (
                        <div className="listDirectSalesOrder">
                            <table className="NewTableDirectSalesOrder">
                                <thead>
                                    <tr>
                                        <th scope="col">Pedido</th>
                                        <th scope="col">Empresa</th>
                                        <th scope="col" className="text-center">
                                            Data
                                        </th>
                                        <th scope="col" className="text-center">
                                            Valor do<br></br> Pedido
                                        </th>
                                        <th scope="col" className="text-center">
                                            Entrega
                                        </th>
                                        <th scope="col" className="text-center">
                                            Tipo Entrega<br></br>Rota
                                        </th>
                                        {/* <th scope="col">Rota</th> */}
                                        <th scope="col">
                                            Status<br></br>Vendedor
                                        </th>
                                        {/* <th scope="col">Vendedor</th> */}
                                        <th scope="col" className="text-center">
                                            Integração
                                        </th>
                                        <th scope="col" className="text-center">
                                            Ações
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {directSaleOrders.data.map(
                                        (item: DirectSaleOrder) => (
                                            <>
                                                <tr
                                                    className="desktopTr"
                                                    key={item.id}
                                                >
                                                    <td data-label="Pedido">
                                                        <div className="orderIdContainer">
                                                            <div className="pixPaymentContainer">
                                                                {item.paymentStatus ==
                                                                    'PAGO' && (
                                                                    <div
                                                                        style={{
                                                                            width: 14,
                                                                            marginRight: 3,
                                                                        }}
                                                                    >
                                                                        <PixLogo
                                                                            title="PIX"
                                                                            style={{
                                                                                color: '#28BCAC',
                                                                                filter: 'invert(61%) sepia(48%) saturate(600%) hue-rotate(124deg) brightness(92%) contrast(91%)',
                                                                            }}
                                                                            fontSize={
                                                                                2
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                                {item.paymentStatus ==
                                                                    'CANCELADO' && (
                                                                    <div
                                                                        style={{
                                                                            width: 14,
                                                                            marginRight: 3,
                                                                        }}
                                                                    >
                                                                        <PixLogo
                                                                            title="PIX"
                                                                            style={{
                                                                                color: '#ff1c1c',
                                                                                filter: 'invert(27%) sepia(91%) saturate(4597%) hue-rotate(350deg) brightness(98%) contrast(107%)',
                                                                            }}
                                                                            fontSize={
                                                                                2
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                                {item.is_accepting_pix ==
                                                                    1 &&
                                                                item.pix_qr_code_texto ? (
                                                                    <MdPix
                                                                        title="Pix não recebido !"
                                                                        color="yellow"
                                                                        size={
                                                                            16
                                                                        }
                                                                    ></MdPix>
                                                                ) : null}
                                                                <p>
                                                                    {!item.paymentStatus &&
                                                                        '#'}
                                                                    {item.id}
                                                                </p>
                                                            </div>
                                                            <p>
                                                                {item.integration && (
                                                                    <p
                                                                        title="Número do pedido externo"
                                                                        className="externalOrder"
                                                                    >
                                                                        {
                                                                            item
                                                                                .integration
                                                                                .external_reference
                                                                        }
                                                                    </p>
                                                                )}
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td
                                                        data-label="Empresa"
                                                        style={{
                                                            textTransform:
                                                                'uppercase',
                                                        }}
                                                    >
                                                        {item.storeName || '-'}
                                                    </td>
                                                    <td
                                                        data-label="Data"
                                                        className="date"
                                                        style={{
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        {/* <div>
                                                        {
                                                            DateService.formatToDisplayWithTime(new Date(item.date.replace(/\s/, 'T')))

                                                        }

                                                    </div> */}
                                                        <div>
                                                            {DateService.formatToDisplay(
                                                                new Date(
                                                                    item.date.replace(
                                                                        /\s/,
                                                                        'T'
                                                                    )
                                                                )
                                                            )}
                                                        </div>
                                                        <div>
                                                            {DateService.formatToDisplayOnlyTime(
                                                                new Date(
                                                                    item.date.replace(
                                                                        /\s/,
                                                                        'T'
                                                                    )
                                                                )
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td
                                                        className="text-center"
                                                        data-label="Valor do Pedido"
                                                    >{`${
                                                        Constants.CURRENCY_SIGNAL
                                                    } ${item.value.toFixed(
                                                        2
                                                    )}`}</td>
                                                    <td
                                                        data-label="Entrega"
                                                        style={{}}
                                                    >
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'column',
                                                                alignItems:
                                                                    'center',
                                                                gap: 3,
                                                                flex: 1,
                                                            }}
                                                        >
                                                            <p>
                                                                {item.deliveryDate
                                                                    ? // ? DateService.formatToDisplay(new Date(item.deliveryDate.replace(/\s/, 'T')))
                                                                      DateService.formatToDisplayShort(
                                                                          new Date(
                                                                              item.deliveryDate.replace(
                                                                                  /\s/,
                                                                                  'T'
                                                                              )
                                                                          )
                                                                      )
                                                                    : '-'}
                                                            </p>
                                                            {item.local ? (
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            'flex',
                                                                        flexDirection:
                                                                            'column',
                                                                        alignItems:
                                                                            'center',
                                                                        gap: 3,
                                                                        flex: 1,
                                                                    }}
                                                                >
                                                                    <p
                                                                        style={{}}
                                                                    >
                                                                        {
                                                                            item
                                                                                .local
                                                                                .descricao
                                                                        }{' '}
                                                                    </p>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </td>
                                                    <td
                                                        data-label="Tipo Entrega/Rota"
                                                        className="tdentregaRotadiv"
                                                    >
                                                        <div
                                                            onClick={() =>
                                                                console.log(
                                                                    item
                                                                )
                                                            }
                                                            className="entregaRotadiv"
                                                        >
                                                            {item.receiving_method ==
                                                                'RETIRADA' &&
                                                            item.id_local_retirada ? (
                                                                <>
                                                                    <p>
                                                                        {item.receiving_method ||
                                                                            '-'}
                                                                    </p>
                                                                    <p>
                                                                        {handleReturnLocalRetirada(
                                                                            item.id_local_retirada
                                                                        )}
                                                                    </p>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <p>
                                                                        {item.receiving_method ||
                                                                            '-'}
                                                                    </p>
                                                                    <p>
                                                                        {item
                                                                            .route
                                                                            ?.description ||
                                                                            '-'}
                                                                    </p>
                                                                </>
                                                            )}
                                                        </div>
                                                    </td>
                                                    {/* <td data-label="Rota">{item.route?.description || '-'}</td> */}
                                                    <td data-label="Status/Vendedor">
                                                        <div className="divStatusVendedor">
                                                            <Status
                                                                status={
                                                                    item.status
                                                                }
                                                            />
                                                            <p
                                                                style={{
                                                                    textAlign:
                                                                        'left',
                                                                    marginTop: 4,
                                                                }}
                                                            >
                                                                {item.seller
                                                                    ?.name ||
                                                                    '-'}
                                                            </p>
                                                        </div>
                                                    </td>
                                                    {/* <td data-label="Vendedor">{item.seller?.name || '-'}</td> */}
                                                    <td
                                                        data-label="Integração"
                                                        className={get_integration_classname(
                                                            item.integration
                                                        )}
                                                    >
                                                        <div className="divIntegrationDSO">
                                                            {item.integration ? (
                                                                //false
                                                                <FiCheckCircle
                                                                    color="#30AA4C"
                                                                    style={{
                                                                        fontSize: 23,
                                                                    }}
                                                                    title="Integrado"
                                                                />
                                                            ) : (
                                                                <BiError
                                                                    color="#CD2A2A"
                                                                    style={{
                                                                        fontSize: 23,
                                                                    }}
                                                                    title="Não Integrado"
                                                                />
                                                            )}
                                                            {item.field_control ? (
                                                                <HiOutlineTruck
                                                                    title={
                                                                        item
                                                                            .field_control
                                                                            .status ==
                                                                        'INTEGRATED'
                                                                            ? 'Field Control integrado !'
                                                                            : 'Field Control não integrado !'
                                                                    }
                                                                    color={
                                                                        item
                                                                            .field_control
                                                                            .status ==
                                                                        'INTEGRATED'
                                                                            ? '#30AA4C'
                                                                            : 'rgb(205, 42, 42);'
                                                                    }
                                                                    className={
                                                                        item
                                                                            .field_control
                                                                            .status ==
                                                                        'INTEGRATED'
                                                                            ? ''
                                                                            : 'callFieldControlButton'
                                                                    }
                                                                    onClick={
                                                                        item
                                                                            .field_control
                                                                            .status ==
                                                                        'INTEGRATED'
                                                                            ? () => {}
                                                                            : () =>
                                                                                  handleSendFieldControl(
                                                                                      item.id
                                                                                  )
                                                                    }
                                                                />
                                                            ) : (
                                                                <HiOutlineTruck
                                                                    title="Field Control"
                                                                    color="#c7c7c7"
                                                                />
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td
                                                        className="TdAcoes"
                                                        data-label="Ações"
                                                    >
                                                        <div className="divTdAcoesDSO">
                                                            {can(
                                                                'UPDATE',
                                                                'DIRECT_PURCHASE'
                                                            ) ? (
                                                                <div
                                                                    style={{
                                                                        height: '100%',
                                                                    }}
                                                                >
                                                                    <BiEditAlt
                                                                        color="#FF7110"
                                                                        style={{
                                                                            fontSize: 20,
                                                                            cursor: 'pointer',
                                                                        }}
                                                                        title="Editar pedido"
                                                                        onClick={() =>
                                                                            can(
                                                                                'UPDATE',
                                                                                'DIRECT_PURCHASE'
                                                                            ) &&
                                                                            handleOpenModal(
                                                                                item
                                                                            )
                                                                        }
                                                                        className={[
                                                                            can(
                                                                                'UPDATE',
                                                                                'DIRECT_PURCHASE'
                                                                            )
                                                                                ? ''
                                                                                : 'disabled',
                                                                            `color-orange`,
                                                                        ].join(
                                                                            ' '
                                                                        )}
                                                                        data-toggle="modal"
                                                                        data-target="#edit-direct-purchase-modal"
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <BiEditAlt
                                                                        style={{
                                                                            fontSize: 20,
                                                                            cursor: 'pointer',
                                                                        }}
                                                                        title="Você não possui permissão para editar pedidos"
                                                                        className="color-orange disabled"
                                                                    />
                                                                </div>
                                                            )}

                                                            <div>
                                                                <BiPrinter
                                                                    color="#17A2B8"
                                                                    style={{
                                                                        fontSize: 20,
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    onClick={() =>
                                                                        print(
                                                                            item
                                                                        )
                                                                    }
                                                                    title="Imprimir"
                                                                />
                                                            </div>
                                                            {
                                                                // item.status === STATUS_WAITING_CONFIRMAION && item.paymentStatus !='CANCELADO'   &&
                                                                item.status ===
                                                                    STATUS_WAITING_CONFIRMAION &&
                                                                    item.paymentStatus !=
                                                                        'CANCELADO' && (
                                                                        <div>
                                                                            <AiOutlineCheckCircle
                                                                                color="#30AA4C"
                                                                                style={{
                                                                                    fontSize: 20,
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                                title="Confirmar"
                                                                                onClick={() =>
                                                                                    confirm(
                                                                                        item
                                                                                    )
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )
                                                            }
                                                            <div>
                                                                <AiOutlineEye
                                                                    color="#707070"
                                                                    style={{
                                                                        fontSize: 22,
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    title="Ver detalhes do pedido"
                                                                    data-target="#order-details"
                                                                    data-toggle="modal"
                                                                    onClick={() =>
                                                                        seeDetails(
                                                                            item
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr
                                                    className="mobileTr"
                                                    key={item.id}
                                                >
                                                    <td
                                                        className="firstTdMobile"
                                                        style={{
                                                            padding:
                                                                '0 !important',
                                                        }}
                                                    >
                                                        <div className="storeNameMobile">
                                                            <div
                                                                style={{
                                                                    textAlign:
                                                                        'left',
                                                                    flex: 1,
                                                                }}
                                                            >
                                                                {item.storeName ||
                                                                    '-'}
                                                            </div>
                                                            <div className="orderIdContainer">
                                                                <div className="pixPaymentContainer">
                                                                    {item.paymentStatus ==
                                                                        'PAGO' && (
                                                                        <div
                                                                            style={{
                                                                                width: 14,
                                                                                marginRight: 3,
                                                                            }}
                                                                        >
                                                                            <PixLogo
                                                                                title="PIX"
                                                                                style={{
                                                                                    color: '#28BCAC',
                                                                                    filter: 'invert(61%) sepia(48%) saturate(600%) hue-rotate(124deg) brightness(92%) contrast(91%)',
                                                                                }}
                                                                                fontSize={
                                                                                    2
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {item.paymentStatus ==
                                                                        'CANCELADO' && (
                                                                        <div
                                                                            style={{
                                                                                width: 14,
                                                                                marginRight: 3,
                                                                            }}
                                                                        >
                                                                            <PixLogo
                                                                                title="PIX"
                                                                                style={{
                                                                                    color: '#ff1c1c',
                                                                                    filter: 'invert(27%) sepia(91%) saturate(4597%) hue-rotate(350deg) brightness(98%) contrast(107%)',
                                                                                }}
                                                                                fontSize={
                                                                                    2
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {item.is_accepting_pix ==
                                                                        1 &&
                                                                    item.pix_qr_code_texto ? (
                                                                        <MdPix
                                                                            title="Pix não recebido !"
                                                                            color="yellow"
                                                                            size={
                                                                                16
                                                                            }
                                                                        ></MdPix>
                                                                    ) : null}
                                                                    {/* <p>
                                    {!item.paymentStatus && '#'}
                                    {item.id}
                                  </p> */}
                                                                </div>
                                                                <div className="infoStack">
                                                                    {/* <p></p> */}
                                                                    <span>
                                                                        {!item.paymentStatus &&
                                                                            '#'}
                                                                        {
                                                                            item.id
                                                                        }
                                                                    </span>
                                                                    {item.integration && (
                                                                        <span
                                                                            title="Número do pedido externo"
                                                                            className="externalOrder"
                                                                        >
                                                                            {
                                                                                item
                                                                                    .integration
                                                                                    .external_reference
                                                                            }
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="dateSellerStatusMobile">
                                                            {/* <div className="infoStack">
                                <p>Vendedor:</p>
                                <span style={{ textAlign: 'left', marginTop: 4 }}>
                                  {item.seller?.name || '-'}
                                </span>
                              </div> */}
                                                            <div className="infoStack">
                                                                <span>
                                                                    {DateService.formatToDisplay(
                                                                        new Date(
                                                                            item.date.replace(
                                                                                /\s/,
                                                                                'T'
                                                                            )
                                                                        )
                                                                    )}
                                                                    <span
                                                                        style={{
                                                                            marginLeft: 6,
                                                                        }}
                                                                    >
                                                                        {DateService.formatToDisplayOnlyTime(
                                                                            new Date(
                                                                                item.date.replace(
                                                                                    /\s/,
                                                                                    'T'
                                                                                )
                                                                            )
                                                                        )}
                                                                    </span>
                                                                </span>
                                                                <span></span>
                                                            </div>
                                                            <div
                                                                className="infoStack"
                                                                style={{
                                                                    gap: 4,
                                                                    alignItems:
                                                                        'flex-end',
                                                                }}
                                                            >
                                                                <Status
                                                                    status={
                                                                        item.status
                                                                    }
                                                                />
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            'flex',
                                                                        gap: 5,
                                                                        alignItems:
                                                                            'center',
                                                                    }}
                                                                >
                                                                    <p>
                                                                        Vend.:
                                                                    </p>
                                                                    <span
                                                                        style={{
                                                                            flex: 1,
                                                                        }}
                                                                    >
                                                                        {item
                                                                            .seller
                                                                            ?.name ||
                                                                            '-'}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="dateSellerStatusMobile">
                                                            <div className="infoStack">
                                                                <p>
                                                                    {' '}
                                                                    <p>
                                                                        {item.receiving_method ||
                                                                            '-'}
                                                                    </p>
                                                                </p>
                                                                <span>
                                                                    {item.deliveryDate
                                                                        ? // ? DateService.formatToDisplay(new Date(item.deliveryDate.replace(/\s/, 'T')))
                                                                          DateService.formatToDisplayShort(
                                                                              new Date(
                                                                                  item.deliveryDate.replace(
                                                                                      /\s/,
                                                                                      'T'
                                                                                  )
                                                                              )
                                                                          )
                                                                        : '-'}
                                                                </span>
                                                            </div>
                                                            {item.route
                                                                ?.description ? (
                                                                <div className="infoStack">
                                                                    <p>Rota</p>
                                                                    <span>
                                                                        {item
                                                                            .route
                                                                            ?.description ||
                                                                            '-'}
                                                                    </span>
                                                                </div>
                                                            ) : null}
                                                            <div
                                                                className="infoStack"
                                                                style={{
                                                                    alignItems:
                                                                        'flex-end',
                                                                }}
                                                            >
                                                                <p>
                                                                    Valor do
                                                                    pedido:
                                                                </p>
                                                                <span>
                                                                    {`${
                                                                        Constants.CURRENCY_SIGNAL
                                                                    }
                                ${item.value.toFixed(2)}`}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="IntegracaoAcoes">
                                                            <div className="divIntegrationDSOMobile">
                                                                {item.integration ? (
                                                                    //false
                                                                    <FiCheckCircle
                                                                        color="#30AA4C"
                                                                        style={{
                                                                            fontSize: 23,
                                                                        }}
                                                                        title="Integrado"
                                                                    />
                                                                ) : (
                                                                    <BiError
                                                                        color="#CD2A2A"
                                                                        style={{
                                                                            fontSize: 23,
                                                                        }}
                                                                        title="Não Integrado"
                                                                    />
                                                                )}
                                                                {item.field_control ? (
                                                                    <div className="buttonAction">
                                                                        <HiOutlineTruck
                                                                            style={{
                                                                                fontSize: 20,
                                                                                cursor: 'pointer',
                                                                            }}
                                                                            title={
                                                                                item
                                                                                    .field_control
                                                                                    .status ==
                                                                                'INTEGRATED'
                                                                                    ? 'Field Control integrado !'
                                                                                    : 'Field Control não integrado !'
                                                                            }
                                                                            color={
                                                                                item
                                                                                    .field_control
                                                                                    .status ==
                                                                                'INTEGRATED'
                                                                                    ? '#30AA4C'
                                                                                    : 'rgb(205, 42, 42);'
                                                                            }
                                                                            className={
                                                                                item
                                                                                    .field_control
                                                                                    .status ==
                                                                                'INTEGRATED'
                                                                                    ? ''
                                                                                    : 'callFieldControlButton'
                                                                            }
                                                                            onClick={
                                                                                item
                                                                                    .field_control
                                                                                    .status ==
                                                                                'INTEGRATED'
                                                                                    ? () => {}
                                                                                    : () =>
                                                                                          handleSendFieldControl(
                                                                                              item.id
                                                                                          )
                                                                            }
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    <HiOutlineTruck
                                                                        title="Field Control"
                                                                        color="#c7c7c7"
                                                                    />
                                                                )}
                                                            </div>
                                                            <div className="divTdAcoesDSOMobile">
                                                                {
                                                                    can(
                                                                        'UPDATE',
                                                                        'DIRECT_PURCHASE'
                                                                    ) ? (
                                                                        <div className="buttonAction">
                                                                            <BiEditAlt
                                                                                color="#FF7110"
                                                                                style={{
                                                                                    fontSize: 20,
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                                title="Editar pedido"
                                                                                onClick={() =>
                                                                                    can(
                                                                                        'UPDATE',
                                                                                        'DIRECT_PURCHASE'
                                                                                    ) &&
                                                                                    handleOpenModal(
                                                                                        item
                                                                                    )
                                                                                }
                                                                                className={[
                                                                                    can(
                                                                                        'UPDATE',
                                                                                        'DIRECT_PURCHASE'
                                                                                    )
                                                                                        ? ''
                                                                                        : 'disabled',
                                                                                    `color-orange`,
                                                                                ].join(
                                                                                    ' '
                                                                                )}
                                                                                data-toggle="modal"
                                                                                data-target="#edit-direct-purchase-modal"
                                                                            />
                                                                        </div>
                                                                    ) : null
                                                                    //   <div>
                                                                    //     <BiEditAlt
                                                                    //       style={{ 'fontSize': 20, 'cursor': 'pointer' }}
                                                                    //       title="Você não possui permissão para editar pedidos"
                                                                    //       className="color-orange disabled" />
                                                                    //   </div>
                                                                }

                                                                <div className="buttonAction">
                                                                    <BiPrinter
                                                                        color="#17A2B8"
                                                                        style={{
                                                                            fontSize: 20,
                                                                            cursor: 'pointer',
                                                                        }}
                                                                        onClick={() =>
                                                                            print(
                                                                                item
                                                                            )
                                                                        }
                                                                        title="Imprimir"
                                                                    />
                                                                </div>
                                                                {
                                                                    // item.status === STATUS_WAITING_CONFIRMAION && item.paymentStatus !='CANCELADO'   &&
                                                                    item.status ===
                                                                        STATUS_WAITING_CONFIRMAION &&
                                                                        item.paymentStatus !=
                                                                            'CANCELADO' && (
                                                                            <div className="buttonAction">
                                                                                <AiOutlineCheckCircle
                                                                                    color="#30AA4C"
                                                                                    style={{
                                                                                        fontSize: 20,
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                    title="Confirmar"
                                                                                    onClick={() =>
                                                                                        confirm(
                                                                                            item
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        )
                                                                }
                                                                <div className="buttonAction">
                                                                    <AiOutlineEye
                                                                        color="#707070"
                                                                        style={{
                                                                            fontSize: 22,
                                                                            cursor: 'pointer',
                                                                        }}
                                                                        title="Ver detalhes do pedido"
                                                                        data-target="#order-details"
                                                                        data-toggle="modal"
                                                                        onClick={() =>
                                                                            seeDetails(
                                                                                item
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            `
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    )}
                                </tbody>
                            </table>

                            {/* <PaginationCustom
                                total={directSaleOrders.last_page}
                                itemsName={'produtos'}
                                ItemsPorPage="30"
                                currentPage={directSaleOrders.current_page}
                                onPageChange={(page: number) =>
                                    setQueryParams({ ...queryParams, page })
                                }
                                totalItems={directSaleOrders.total}
                            /> */}

                            <PaginationCustomV3
                                totalItems={directSaleOrders.total} // Total de itens, por exemplo, 2890
                                currentPage={directSaleOrders.current_page} // A página atual
                                handlePageChange={(page: number) =>
                                    setQueryParams({
                                        ...queryParams,
                                        page,
                                    })
                                }
                                itemsPerPage={directSaleOrders.per_page} // Número de itens por página
                                itemsName="pedidos"
                            />
                        </div>
                    ) : (
                        <FeedbackError
                            message={Constants.MSG_DATA_NOT_FOUND}
                            style={{ zIndex: -1 }}
                        />
                    )
                ) : (
                    <Loader type="inline" />
                )}
            </div>

            {printOrder && (
                <div className="print-content">
                    <DirectSaleOrderPrint
                        order={printOrder}
                        ref={componentRef}
                    />
                </div>
            )}
            {
                //  <DirectSaleOrderModalDetails order={detailsOrder} />
            }
            <BaseModal
                data={detailsOrder}
                title={'Detalhes do Pedido'}
                isVisible={detailsOrderIsVisible}
                onCloseCallback={() => {
                    setDetailsOrderIsVisible(false)
                }}
                modalBodyStyle={{ height: 'unset', padding: '30px 0px' }}
                children={
                    <DirectSaleOrderDetails
                        locales={locales}
                        order={detailsOrder}
                    />

                    //  <EditDirectPurchaseModalComponent routes={routes}
                    //          data={detailsOrder}
                    //          onUpdate={() => search()}
                    //          onCloseCallback={()=>setEditOrderModalIsVisible(false) }
                    //          />
                }
            />
            <BaseModal
                data={detailsOrder}
                title={`Pedido #${detailsOrder ? detailsOrder.id : ''}`}
                isVisible={editOrderModalIsVisible}
                onCloseCallback={() => {
                    setEditOrderModalIsVisible(false)
                }}
                modalBodyStyle={{ height: 'unset', overflowY: 'visible' }}
                children={
                    <EditDirectPurchaseModalComponent
                        routes={routes}
                        data={detailsOrder}
                        onUpdate={() => search()}
                        onCloseCallback={() =>
                            setEditOrderModalIsVisible(false)
                        }
                    />
                }
            />

            {
                //   <EditDirectPurchaseModalComponent routes={routes}
                //       data={detailsOrder}
                //       onUpdate={() => search()} />
            }
            {storeId && (
                <DirectOrderDeliveryModal
                    storeId={storeId}
                    onCloseCallback={() => setStoreId(null)}
                />
            )}
        </ProjectContainer>
    )
}

const mapStateToProps = (state: any) => ({
    directSaleOrders: state.directSaleOrders,
    customers: state.customers,
})

const mapDispatchToProps = (dispatch: any) => ({
    findOrders: (params: any) => dispatch(loadDirectSaleOrders(params)),
    findCustomers: () => dispatch(loadCustomers(1)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DirectSaleOrderList)
