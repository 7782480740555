import { FC, useEffect, useState } from 'react'
import Pagination from 'react-bootstrap/Pagination'
// O CSS UTILIZADO ESTÁ NO COMPONENTE 'pagination-custom'

interface PaginationCustomV2Props {
    totalItems: number
    currentPage: number
    handlePageChange: (page: number) => void
    itemsPerPage: number
    itemsName: string
}

export const PaginationCustomV3: FC<PaginationCustomV2Props> = ({
    totalItems,
    currentPage,
    handlePageChange,
    itemsPerPage,
    itemsName,
}) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage)
    const [items, setItems] = useState<JSX.Element[]>([])
    const [from, setFrom] = useState(0)
    const [to, setTo] = useState(0)
    const [maxVisiblePages, setMaxVisiblePages] = useState(10)

    useEffect(() => {
        const updateMaxVisiblePages = () => {
            if (window.innerWidth <= 480) {
                setMaxVisiblePages(2)
            } else if (window.innerWidth <= 638) {
                setMaxVisiblePages(5)
            } else {
                setMaxVisiblePages(10)
            }
        }

        updateMaxVisiblePages()
        window.addEventListener('resize', updateMaxVisiblePages)

        return () => {
            window.removeEventListener('resize', updateMaxVisiblePages)
        }
    }, [])

    useEffect(() => {
        const paginationItems: JSX.Element[] = []

        const start = (currentPage - 1) * itemsPerPage + 1
        const end = Math.min(currentPage * itemsPerPage, totalItems)
        setFrom(start)
        setTo(end)

        paginationItems.push(
            <Pagination.First
                key="first"
                onClick={() =>
                    handlePageChange(Math.max(currentPage - maxVisiblePages, 1))
                }
                disabled={currentPage <= 1}
            />
        )

        paginationItems.push(
            <Pagination.Prev
                key="prev"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
            />
        )

        const startPage = Math.max(
            1,
            currentPage - Math.floor(maxVisiblePages / 2)
        )
        const endPage = Math.min(
            totalPages,
            currentPage + Math.floor(maxVisiblePages / 2)
        )

        if (startPage > 1) {
            paginationItems.push(
                <Pagination.Item key={1} onClick={() => handlePageChange(1)}>
                    {1}
                </Pagination.Item>
            )
            if (startPage > 2) {
                paginationItems.push(
                    <Pagination.Ellipsis key="ellipsis-start" />
                )
            }
        }

        for (let number = startPage; number <= endPage; number++) {
            paginationItems.push(
                <Pagination.Item
                    key={number}
                    active={number === currentPage}
                    onClick={() => handlePageChange(number)}
                >
                    {number}
                </Pagination.Item>
            )
        }

        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                paginationItems.push(<Pagination.Ellipsis key="ellipsis-end" />)
            }
            paginationItems.push(
                <Pagination.Item
                    key={totalPages}
                    onClick={() => handlePageChange(totalPages)}
                >
                    {totalPages}
                </Pagination.Item>
            )
        }

        paginationItems.push(
            <Pagination.Next
                key="next"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
            />
        )

        paginationItems.push(
            <Pagination.Last
                key="last"
                onClick={() =>
                    handlePageChange(
                        Math.min(currentPage + maxVisiblePages, totalPages)
                    )
                }
                disabled={currentPage >= totalPages}
            />
        )

        setItems(paginationItems)
    }, [totalItems, currentPage, maxVisiblePages])

    return (
        <>
            <Pagination>{items}</Pagination>
            <div className="pagination-desc">
                <p>
                    Exibindo: {from} até {to} de {totalItems} registros de{' '}
                    {itemsName}
                </p>
            </div>
        </>
    )
}
