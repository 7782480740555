import HttpClient from './http.client'

interface ChangeStatusServiceParams {
    pedido_id: number
    status: string
}

export default class StatusService {
    static changeStatus({
        pedido_id,
        status,
    }: ChangeStatusServiceParams): Promise<any> {
        return HttpClient.put(`providers/pedido/direto/${pedido_id}`, {
            status,
        })
    }
}
