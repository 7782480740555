import ProductModel from 'models/product.model'
import React, { useReducer } from 'react'
import DeliveryService from 'services/DeliveryService'
import RequestService from 'services/RequestService'
import RequestServiceV3 from 'services/v3/request.service'
import RequestReducer, {
    LOAD_REQUESTS_SUCCESS,
    SET_DELIVERY,
    SET_REQUEST,
    SET_REQUEST_PRODUCTS,
    SET_REQUEST_PRODUCTS_PAGINATION,
} from './reducer'

const state: any = {
    status_list: [
        { status_nome: 'Digitada', status_id: 'TYPED' },
        { status_nome: 'Não Digitada', status_id: 'NOT_TYPED' },
        { status_nome: 'Sem Pedido', status_id: 'NOT_ORDERED' },
        { status_nome: 'Pedido Gerado', status_id: 'ORDERED' },
        { status_nome: 'Congelada', status_id: 'FROZEN' },
    ],
    states: [
        { name: 'Acre', value: 'AC', est_id: 1 },
        { name: 'Alagoas', value: 'AL', est_id: 2 },
        { name: 'Amazonas', value: 'AM', est_id: 3 },
        { name: 'Amapá', value: 'AP', est_id: 4 },
        { name: 'Bahia', value: 'BA', est_id: 5 },
        { name: 'Ceará', value: 'CE', est_id: 6 },
        { name: 'Distrito Federal', value: 'DF', est_id: 7 },
        { name: 'Espírito Santo', value: 'ES', est_id: 8 },
        { name: 'Goiás', value: 'GO', est_id: 9 },
        { name: 'Maranhão', value: 'MA', est_id: 10 },
        { name: 'Minas Gerais', value: 'MG', est_id: 11 },
        { name: 'Mato Grosso do Sul', value: 'MS', est_id: 12 },
        { name: 'Mato Grosso', value: 'MT', est_id: 13 },
        { name: 'Pará', value: 'PA', est_id: 14 },
        { name: 'Paraíba', value: 'PB', est_id: 15 },
        { name: 'Pernambuco', value: 'PE', est_id: 16 },
        { name: 'Piauí', value: 'PI', est_id: 17 },
        { name: 'Paraná', value: 'PR', est_id: 18 },
        { name: 'Rio de Janeiro', value: 'RJ', est_id: 19 },
        { name: 'Rio Grande do Norte', value: 'RN', est_id: 20 },
        { name: 'Rondônia', value: 'RO', est_id: 21 },
        { name: 'Roraima', value: 'RR', est_id: 22 },
        { name: 'Rio Grande do Sul', value: 'RS', est_id: 23 },
        { name: 'Santa Catarina', value: 'SC', est_id: 24 },
        { name: 'Sergipe', value: 'SE', est_id: 25 },
        { name: 'São Paulo', value: 'SP', est_id: 26 },
        { name: 'Tocantins', value: 'TO', est_id: 27 },
    ],
}

const RequestContext = React.createContext(state)

export const RequestProvider = ({ children }: any) => {
    const [requests, dispatch] = useReducer(RequestReducer, state)

    const load_requests = async (params: any) =>
        RequestService.getAll(params)
            .then((response) =>
                dispatch({ type: LOAD_REQUESTS_SUCCESS, requests: response })
            )
            .catch(() =>
                dispatch({ type: LOAD_REQUESTS_SUCCESS, requests: null })
            )

    const load_others_requests = async (params: any) =>
        RequestService.load_others_request(params)
            .then((response) =>
                dispatch({ type: SET_REQUEST, request: response })
            )
            .catch(() => dispatch({ type: SET_REQUEST, request: null }))

    const load_request_products = async (
        id: number,
        seller_id: number,
        page,
        category,
        name,
        digitados,
        meusProdutos,
        ratio,
        for_report
    ) => {
        console.log('for-report', for_report)
        let paginationData
        return RequestServiceV3.load_request_products(
            id,
            seller_id,
            1,
            page,
            category,
            name,
            digitados,
            meusProdutos,
            ratio,
            for_report
        )
            .then(async (products) => {
                paginationData = products.meta ? products.meta : {}
                products.data.forEach((p: any) => {
                    p.derivatives = p.derivatives.map(
                        (pp: any) => new ProductModel(pp)
                    )
                })

                return products.data.map((p: any) => new ProductModel(p))
            })
            .then((products) => {
                return RequestServiceV3.load_request_last_offers_by_seller(
                    id,
                    seller_id
                ).then((result: any) => {
                    products.forEach((p: ProductModel) => {
                        p.process_last_offer(result)
                    })
                    return products
                })
            })
            .then((products) =>
                dispatch({ type: SET_REQUEST_PRODUCTS, products })
            )
            .then((products) => {
                dispatch({
                    type: SET_REQUEST_PRODUCTS_PAGINATION,
                    paginationData,
                })
            })
            .catch(() =>
                dispatch({ type: SET_REQUEST_PRODUCTS, products: null })
            )
    }

    const load_request = async (id: number) =>
        RequestService.load_request(id)
            .then((response) =>
                dispatch({ type: SET_REQUEST, request: response })
            )
            .catch(() => dispatch({ type: SET_REQUEST, request: null }))

    const load_request_seller_delivery = async (
        request_id: number,
        seller_id: number
    ) =>
        DeliveryService.request_seller_delivery(request_id, seller_id)
            .then((delivery) => {
                dispatch({ type: SET_DELIVERY, delivery })
                return delivery
            })
            .catch(() => dispatch({ type: SET_DELIVERY, delivery: null }))

    const load_last_request_seller_delivery = async (
        request_id: number,
        seller_id: number
    ) =>
        DeliveryService.last_request_seller_delivery(request_id, seller_id)
            .then((delivery) => {
                dispatch({
                    type: SET_DELIVERY,
                    delivery: { ...delivery, old: true },
                })
                return delivery
            })
            .catch(() => dispatch({ type: SET_DELIVERY, delivery: null }))

    const set_delivery = (delivery: any) =>
        dispatch({ type: SET_DELIVERY, delivery })

    const ctx = {
        ...requests,
        load_requests,
        load_request,
        load_request_products,
        load_others_requests,
        load_request_seller_delivery,
        set_delivery,
        load_last_request_seller_delivery,
    }

    return (
        <RequestContext.Provider value={ctx}>
            {children}
        </RequestContext.Provider>
    )
}

export default RequestContext
