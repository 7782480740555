import React, { FC, useEffect, useLayoutEffect, useState } from 'react'
import styles from './index.module.scss'
import ModalComponent from 'components/modal-v2'
import { mask_cnpj } from 'helpers/mask.helper'
import {
    AiOutlineUsergroupAdd,
    AiOutlineCheckCircle,
    AiOutlineCloseCircle,
    AiOutlineSearch,
    AiOutlineDown,
} from 'react-icons/ai'
import CustomerService from 'services/CustomerService'
import { Button } from 'components/button'
import SnackBarSuccessStyle from 'styles/snackbar-success.style'
import { useSnackbar } from 'react-simple-snackbar'
import { Snackbar } from 'components/snackbar'
import { PhoneWithWhats } from 'components/phoneWithWhats'
import InputV2 from 'components/InputV2'
import IconReact from 'components/icons'
import { BaseModal } from 'components/BaseModal'
import { Select } from 'components/select'
import styled from 'styled-components'
import ButtonV3 from 'components/Buttonv3'
import SalesmanService from 'services/SalesmanService'
import { NewClientsFilter } from './components/filter'
import { FeedbackError } from 'components/feedback/error'

export const NewClientsModal: FC<any> = ({
    user,
    clients,
    setNewClients,
    clientsByState,
    handleFilterState,
    registerAmount,
    filterdStateID,
}: any) => {
    const [modal, setModal] = useState<any>({})
    const [selectSellerVisible, setSelectSellerVisible] = useState('')
    const [openSnackbar] = useSnackbar(SnackBarSuccessStyle)
    const [salesmen, setSalesmen] = useState<any>([])
    const [seller, setSeller] = useState<any>('')
    const [filteredClients, setFilteredClients] = useState(clients)

    useEffect(() => {
        setModal({
            title: <span>Novos Clientes</span>,
            icon: <AiOutlineUsergroupAdd />,
            styles: {
                maxWidth: '80vw',
            },
        })
    }, [clients])

    useLayoutEffect(() => {
        const selectId =
            user.store && user.store.est_id ? user.store.est_id : null
        handleFilterState(selectId)
        SalesmanService.getAll().then((data) => setSalesmen(data))
        setSeller(user.id)
    }, [])

    function handleAssociate(client: any, assoc: any) {
        setSelectSellerVisible(client.cli_id)
    }

    const toggle_client = (client: any, assoc: any) => {
        CustomerService.associate(seller, client.cli_id, !assoc).then(() => {
            client.active = assoc
            const clis = clients
                .filter((c: any) => c.active === undefined)
                .slice(0)
            setNewClients(clis)
        })
        if (!assoc) {
            CustomerService.ignore({ cli_id: client.cli_id, vend_id: seller })
        }
        openSnackbar(
            <Snackbar icon={assoc ? 'person_add' : 'person_add_disabled'}>
                {assoc
                    ? 'Cliente adicionado com sucesso!'
                    : 'Cliente descartado com sucesso!'}
            </Snackbar>
        )
    }
    const handleFilter = (selectedSegment: number | null) => {
        const filtered = clients.filter((client: any) => {
            const segmentId = Number(selectedSegment)
            return segmentId ? client.ramo_id === segmentId : true
        })
        setFilteredClients(filtered)
    }

    const handleClearFilter = () => {
        setFilteredClients(clients)
    }

    const close = () => {
        setNewClients(null)
    }

    return (
        <div className={styles.contentContainer}>
            {/*
             //! filtrado para mostrar apenas os do estado do usuario logado
            <div className={styles.filterStateContainerMobile}>
                <p >Selecione um Estado</p>
                <div>
                    <Select
                        id="seller"
                        value_key="est_id"
                        label_key={"est_nome"}
                        // blank_label="Selecione um vendedor"
                        options={clientsByState}
                        value={filterdStateID}
                        onChange={(e: any) => handleFilterState(parseInt(e.target.value))}
                    />

                </div>
            </div> */}
            {/*
            //! filtrado para mostrar apenas os do estado do usuario logado

            <div className={styles.filterStateContainer}>
                <div className={styles.filterStateContainerList}>
                    <h5>Estado/Nº de clientes</h5>
                    <p className={filterdStateID == 0 || null ? styles.active : styles.inactive} onClick={() => handleFilterState(0)}>Ver Todos ({registerAmount})</p>
                    {clientsByState && clientsByState.map(item => (
                        <p
                            className={filterdStateID == item.est_id ? styles.active : styles.inactive}
                            onClick={() => handleFilterState(item.est_id)}
                        >{item.est_nome} ({item.cli_qtd} { })</p>
                    ))}
                </div>

            </div> */}
            <div className={styles.contentFilters}>
                <NewClientsFilter
                    onFilter={handleFilter}
                    onClear={handleClearFilter}
                />
            </div>
            <div className={styles.listNewClients}>
                {filteredClients.length > 0 ? (
                    <table className={styles.newTableNewClients}>
                        <thead>
                            <tr>
                                <th>Nome/CNPJ</th>
                                {/* <th>Cidade</th> */}
                                {/* <th>Email</th> */}
                                <th>Contato/Email</th>
                                <th>Cidade/Telefone</th>
                                <th>Tipo Entrega</th>
                                <th>Segmento</th>
                                <th className="text-center">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredClients &&
                                filteredClients
                                    .filter((c: any) => c.active === undefined)
                                    .map((c: any, index) => (
                                        <>
                                            {' '}
                                            <tr
                                                style={{
                                                    backgroundColor:
                                                        selectSellerVisible ==
                                                        ''
                                                            ? ''
                                                            : selectSellerVisible ==
                                                              c.cli_id
                                                            ? '#fff'
                                                            : '#F7F7F7',
                                                }}
                                                key={index}
                                            >
                                                {/* <td>{mask_cnpj(c.cli_cnpj)}</td> */}
                                                <td data-label="Nome/CNPJ">
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection:
                                                                'column',
                                                        }}
                                                    >
                                                        <p>{c.cli_nome}</p>
                                                        <p>
                                                            {mask_cnpj(
                                                                c.cli_cnpj
                                                            )}
                                                        </p>
                                                    </div>
                                                </td>
                                                {/* <td>{c.cid_nome}</td> */}
                                                {/* <td>{c.cli_email}</td> */}
                                                <td data-label="Contato/Email">
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection:
                                                                'column',
                                                        }}
                                                    >
                                                        <p>{c.cli_contato}</p>
                                                        <p> {c.cli_email}</p>
                                                    </div>
                                                </td>
                                                <td data-label="Cidade/Telefone">
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection:
                                                                'column',
                                                        }}
                                                    >
                                                        <p>{c.cid_nome}</p>
                                                        <p>
                                                            <PhoneWithWhats
                                                                phone={
                                                                    c.cli_telefone
                                                                }
                                                            />
                                                        </p>
                                                    </div>
                                                </td>
                                                <td data-label="Tipo Entrega">
                                                    {c.tpc_id === 1 && (
                                                        <span
                                                            title="Aceita apenas quem realiza entrega"
                                                            className={
                                                                styles.cif
                                                            }
                                                        >
                                                            CIF
                                                        </span>
                                                    )}
                                                    {c.tpc_id === 2 && (
                                                        <span
                                                            title="Aceita quem realiza e quem não realiza entrega"
                                                            className={
                                                                styles.ciffob
                                                            }
                                                        >
                                                            CIF / FOB
                                                        </span>
                                                    )}
                                                    {c.tpc_id === 3 && (
                                                        <span
                                                            title="Aceita quem não realiza entrega"
                                                            className={
                                                                styles.ciffob
                                                            }
                                                        >
                                                            FOB
                                                        </span>
                                                    )}
                                                </td>
                                                <td data-label="Segmento">
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection:
                                                                'column',
                                                        }}
                                                    >
                                                        <p>
                                                            {c.ramo_descricao}
                                                        </p>
                                                    </div>
                                                </td>
                                                <td data-label="Ações">
                                                    <div
                                                        className={
                                                            styles.actionDiv
                                                        }
                                                    >
                                                        {selectSellerVisible ==
                                                        c.cli_id ? (
                                                            <div>
                                                                <ButtonV3
                                                                    style={{
                                                                        height: 25,
                                                                    }}
                                                                    title="Cancelar"
                                                                    onClick={() =>
                                                                        setSelectSellerVisible(
                                                                            ''
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <div
                                                                    title="Atender"
                                                                    onClick={() =>
                                                                        handleAssociate(
                                                                            c,
                                                                            true
                                                                        )
                                                                    }
                                                                    className={
                                                                        styles.acceptButtonNewClients
                                                                    }
                                                                >
                                                                    <AiOutlineCheckCircle></AiOutlineCheckCircle>
                                                                </div>
                                                                <div
                                                                    title="Rejeitar"
                                                                    onClick={() =>
                                                                        toggle_client(
                                                                            c,
                                                                            false
                                                                        )
                                                                    }
                                                                    className={
                                                                        styles.rejectButtonNewClients
                                                                    }
                                                                >
                                                                    <AiOutlineCloseCircle />
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                            {selectSellerVisible == c.cli_id ? (
                                                <tr
                                                    style={{
                                                        backgroundColor: '#fff',
                                                    }}
                                                >
                                                    <td colSpan={5}>
                                                        <SelectSellerDiv
                                                            visible={
                                                                selectSellerVisible !==
                                                                ''
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    styles.containerSelectSeller
                                                                }
                                                            >
                                                                <h6>
                                                                    Selecione um
                                                                    Vendedor:{' '}
                                                                </h6>

                                                                <Select
                                                                    id="seller"
                                                                    value_key="id"
                                                                    label_key="name"
                                                                    blank_label="Selecione um vendedor"
                                                                    options={
                                                                        salesmen
                                                                    }
                                                                    value={
                                                                        seller
                                                                    }
                                                                    onChange={(
                                                                        e: any
                                                                    ) =>
                                                                        setSeller(
                                                                            parseInt(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        )
                                                                    }
                                                                />
                                                                <ButtonV3
                                                                    style={{
                                                                        marginLeft: 10,
                                                                        height: 41,
                                                                    }}
                                                                    filled
                                                                    title="Salvar"
                                                                    onClick={() =>
                                                                        toggle_client(
                                                                            c,
                                                                            true
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </SelectSellerDiv>
                                                    </td>
                                                </tr>
                                            ) : null}
                                        </>
                                    ))}
                        </tbody>
                    </table>
                ) : (
                    <FeedbackError message="Nenhum cliente encontrado." />
                )}
            </div>
            {/* <BaseModal

                modalBodyStyle={{ 'height': 'unset', width: 'unset' }}
                title={'Adicionar Vendedor'}
                isVisible={selectSellerVisible}
                onCloseCallback={() => setSelectSellerVisible(false)}
                children={<div> teste</div>
                }
            /> */}
        </div>
    )

    //   return <ModalComponent modal={ modal } show={ true } close={ close } className={ styles['modal-dialog'] }>
    //       <div className="container-fluid">
    //           <div className="row header">
    //               <div className="col m-4 p-3 shadow">
    //                   <table className={ `table ${styles.table}` }>
    //                       <thead>
    //                           <tr>
    //                               <th>CNPJ</th>
    //                               <th>Nome</th>
    //                               <th>Cidade</th>
    //                               <th>Email</th>
    //                               <th>Contato</th>
    //                               <th>Telefone</th>
    //                               <th>Tipo Entrega</th>
    //                               <th></th>
    //                           </tr>
    //                       </thead>
    //                       <tbody>
    //                           { clients && clients.filter((c: any) => c.active === undefined )
    //                               .map((c: any) => <tr key={ c.cli_id }>
    //                                   <td>{ mask_cnpj(c.cli_cnpj) }</td>
    //                                   <td>{ c.cli_nome }</td>
    //                                   <td>{ c.cid_nome }</td>
    //                                   <td>{ c.cli_email }</td>
    //                                   <td>{ c.cli_contato }</td>
    //                                   <td>{ c.cli_telefone }</td>
    //                                   <td>
    //                                       { c.tpc_id === 1 && <span title="Aceita apenas quem realiza entrega" className={ styles.cif }>Apenas CIF</span> }
    //                                       { c.tpc_id === 2 && <span title="Aceita quem realiza e quem não realiza entrega" className={ styles.ciffob }>CIF / FOB</span> }
    //                                       { c.tpc_id === 3 && <span title="Aceita quem não realiza entrega" className={ styles.ciffob }>FOB</span> }
    //                                   </td>
    //                                   <td>
    //                                       <div className={ styles.actions }>
    //                                           <Button onClick={ () => toggle_client(c, true) } className="c-btn-save">
    //                                               Atender
    //                                           </Button>
    //                                           <Button onClick={ () => toggle_client(c, false) } className="c-btn-remove">
    //                                               Descartar
    //                                           </Button>
    //                                       </div>
    //                                   </td>
    //                               </tr>) }
    //                       </tbody>
    //                   </table>
    //               </div>
    //           </div>
    //       </div>
    //   </ModalComponent>
}
const SelectSellerDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    /* height: ${(props) => (props.visible ? 'unset' : 0)}; */
    animation: visible 400ms ease-in-out;

    @keyframes visible {
        0% {
            transform: translateY(-20%);
            opacity: 0;
        }

        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }
`
