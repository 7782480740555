import React, { FC, useContext, useEffect } from 'react'
import DateService from '../../../../services/DateService'
import Utils from '../../../../utils'
import { DirectSaleOrderItem } from '../../../../interfaces/directSaleOrderItem'
import { Status } from '../../../../components/status'
import styles from './index.module.scss'
import { mask_cnpj, phoneMask } from 'helpers/mask.helper'
import { StatusBox } from './components/styles'
import { StatusBoxDeliryOrder } from './components/statusBoxDeliveryOrder'
import { PhoneWithWhats } from 'components/phoneWithWhats'
import { UserContext } from 'contexts/user'

export const DirectSaleOrderDetails: FC<any> = ({ locales, order }: any) => {
    const { user } = useContext(UserContext)
    function handleReturnLocalRetirada(id) {
        if (locales && id) {
            let local = locales.find((item) => item.id == id)
            if (local) {
                return local.descricao
            }
        }
        return false
    }

    return (
        <div className="container-fluid">
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <section className={styles.sectionContainerDSOD}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <div className={styles.divTitleValueTitle}>
                            <p className={styles.titleDSODTitleDescription}>
                                Dados do fornecedor:
                            </p>
                            <p className={styles.titleDSODvalueDescription}>
                                {' '}
                                {order.provider.socialReason}
                            </p>
                        </div>

                        <div className={styles.divTitleValue}>
                            <p className={styles.DSODTitleDescription}>
                                CNPJ:{' '}
                            </p>
                            <p className={styles.DSODvalueDescription}>
                                {' '}
                                {order.provider.cnpj &&
                                    mask_cnpj(order.provider.cnpj)}
                            </p>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <div
                                style={{ marginRight: 15 }}
                                className={styles.divTitleValue}
                            >
                                <p className={styles.DSODTitleDescription}>
                                    Telefone:{' '}
                                </p>
                                <p
                                    style={{ textAlign: 'center' }}
                                    className={styles.DSODvalueDescription}
                                >
                                    {' '}
                                    {order.provider.phone && (
                                        <PhoneWithWhats
                                            phone={order.provider.phone}
                                        />
                                    )}
                                </p>
                                {/* <p className={styles.DSODvalueDescription}> {order.provider.phone && phoneMask(order.provider.phone)}</p> */}
                            </div>
                            <div className={styles.divTitleValue}>
                                <p className={styles.DSODTitleDescription}>
                                    Email:{' '}
                                </p>
                                <p className={styles.DSODvalueDescription}>
                                    {' '}
                                    {order.provider.email}
                                </p>
                            </div>
                        </div>

                        <div className={styles.divTitleValue}>
                            <p className={styles.DSODTitleDescription}>CEP: </p>
                            <p className={styles.DSODvalueDescription}>
                                {' '}
                                {user?.store.loj_cep}
                            </p>
                        </div>
                        <div className={styles.divTitleValue}>
                            <p className={styles.DSODTitleDescription}>
                                Endereço:{' '}
                            </p>
                            <p className={styles.DSODvalueDescription}>
                                {' '}
                                {user.store.loj_endereco}
                            </p>
                        </div>
                        <div className={styles.divTitleValue}>
                            <p className={styles.DSODTitleDescription}>
                                Bairro:{' '}
                            </p>
                            <p className={styles.DSODvalueDescription}>
                                {' '}
                                {user.store.loj_bairro}
                            </p>
                        </div>
                    </div>
                    <div className={styles.divContainerOrderDetailsDSOD}>
                        <div className={styles.divTitleValue}>
                            <p className={styles.titleDSODTitleDescription}>
                                Detalhe do Pedido
                            </p>
                        </div>
                        <div className={styles.divTitleValueDetailsBox}>
                            <p className={styles.DSODTitleDescription}>
                                Pedido:{' '}
                            </p>
                            <p className={styles.DSODvalueDescriptionBox}>
                                {order.id}
                            </p>
                        </div>
                        {order.deliveryDate && (
                            <div className={styles.divTitleValueDetailsBox}>
                                <p className={styles.DSODTitleDescription}>
                                    {order.receiving_method == 'RETIRADA' &&
                                    order.id_local_retirada
                                        ? `Retirada/\nLocal`
                                        : order.receiving_method == 'RETIRADA'
                                        ? 'Retirada'
                                        : 'Entrega'}
                                </p>
                                {order.id_local_retirada ? (
                                    <>
                                        <p
                                            className={
                                                styles.DSODvalueDescriptionBox
                                            }
                                        >
                                            {' '}
                                            {DateService.formatToDisplay(
                                                new Date(order.deliveryDate)
                                            )}{' '}
                                            -{' '}
                                            {handleReturnLocalRetirada(
                                                order.id_local_retirada
                                            )}
                                        </p>
                                    </>
                                ) : (
                                    <>
                                        <p
                                            className={
                                                styles.DSODvalueDescriptionBox
                                            }
                                        >
                                            {' '}
                                            {DateService.formatToDisplay(
                                                new Date(order.deliveryDate)
                                            )}
                                        </p>
                                    </>
                                )}
                            </div>
                        )}
                        <div className={styles.divTitleValueDetailsBox}>
                            <p className={styles.DSODTitleDescription}>
                                Vendedor:{' '}
                            </p>
                            <p className={styles.DSODvalueDescriptionBox}>
                                {' '}
                                {order.seller.name ? order.seller.name : '**'}
                            </p>
                        </div>
                        <div className={styles.divTitleValueDetailsBox}>
                            <p className={styles.DSODTitleDescription}>
                                Valor total:{' '}
                            </p>
                            {/* <p className={styles.DSODvalueDescriptionBox}> {order.value ?`R$ ${order.value.toFixed(2)}`:'--'}</p> */}
                            <p className={styles.DSODvalueDescriptionBox}>
                                {' '}
                                {order.value
                                    ? Utils.formatPrice(order.value)
                                    : '--'}
                            </p>
                        </div>
                        {order.campaign_id && (
                            <div className={styles.divTitleValueDetailsBox}>
                                <p className={styles.DSODTitleDescription}>
                                    Campanha:
                                </p>
                                <p className={styles.DSODvalueDescriptionBox}>
                                    #{order.campaign?.id}{' '}
                                    {order.campaign?.titulo}
                                </p>
                            </div>
                        )}
                        <div className={styles.divTitleValueDetailsBox}>
                            <p className={styles.DSODTitleDescription}>
                                Status:{' '}
                            </p>
                            <StatusBoxDeliryOrder value={order.status} />
                            {
                                //   <p className={styles.DSODvalueDescriptionBox}> {order.status}</p>
                            }
                        </div>

                        {
                            //   <td><strong>Data:</strong>
                            //       {DateService.formatToDisplayWithTime(new Date(order.date))}
                            //   </td>
                        }
                    </div>
                </section>

                <section
                    style={{
                        border: '1px solid #DADADA',
                        padding: 5,
                        marginTop: 15,
                    }}
                >
                    <div className={styles.divTitleValue}>
                        <p className={styles.titleDSODTitleDescription}>
                            Dados do Comprador:{' '}
                        </p>
                        <p className={styles.titleDSODvalueDescription}>
                            {' '}
                            {order.lojaCondicao.name}
                        </p>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                gap: '15%',
                                width: '100%',
                            }}
                        >
                            <div className={styles.divTitleValue}>
                                <p className={styles.DSODTitleDescription}>
                                    CNPJ:{' '}
                                </p>
                                <p className={styles.DSODvalueDescription}>
                                    {' '}
                                    {mask_cnpj(order.lojaCondicao.cnpj)}
                                </p>
                            </div>
                            <div className={styles.divTitleValue}>
                                <p className={styles.DSODTitleDescription}>
                                    Comprador Requisitante:{' '}
                                </p>
                                <p className={styles.DSODvalueDescription}>
                                    {' '}
                                    {order.usu_name}
                                </p>
                            </div>
                            <div className={styles.divTitleValue}>
                                <p className={styles.DSODTitleDescription}>
                                    Telefone:{' '}
                                </p>
                                <p className={styles.DSODvalueDescription}>
                                    {order.lojaCondicao.phone && (
                                        <PhoneWithWhats
                                            phone={order.lojaCondicao.phone}
                                        />
                                    )}{' '}
                                </p>
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                gap: '8%',
                                width: '100%',
                            }}
                        >
                            <div className={styles.divTitleValue}>
                                <p className={styles.DSODTitleDescription}>
                                    Email:{' '}
                                </p>
                                <p className={styles.DSODvalueDescription}>
                                    {' '}
                                    {order.lojaCondicao.email}
                                </p>
                            </div>
                            <div className={styles.divTitleValue}>
                                <p className={styles.DSODTitleDescription}>
                                    CEP:{' '}
                                </p>
                                <p className={styles.DSODvalueDescription}>
                                    {' '}
                                    {order.lojaCondicao.cep}
                                </p>
                            </div>
                            <div className={styles.divTitleValue}>
                                <p className={styles.DSODTitleDescription}>
                                    Endereço:{' '}
                                </p>
                                <p className={styles.DSODvalueDescription}>
                                    {' '}
                                    {order.lojaCondicao.address}
                                </p>
                            </div>
                            <div className={styles.divTitleValue}>
                                <p className={styles.DSODTitleDescription}>
                                    Bairro:{' '}
                                </p>
                                <p className={styles.DSODvalueDescription}>
                                    {' '}
                                    {order.lojaCondicao.neighborhood}
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    //  <div>
                    //      <img src="/images/logo-sign-in.jpg" alt="logo" />
                    //      <Status status={order.status} />
                    //  </div>
                }
                <div className={styles.listDirectSalesOrderDetails}>
                    <table className={styles.NewTableDirectSalesOrderDetails}>
                        <thead>
                            <tr>
                                <th>EAN</th>
                                <th>Nome</th>
                                <th>Embalagem</th>
                                <th>Quantidade</th>
                                {order.campaign_id && <th>Preço UN</th>}
                                <th>Preço Embalagem</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {order.items.map(
                                (item: DirectSaleOrderItem, i: number) => (
                                    <tr key={i}>
                                        <td data-label="EAN">
                                            {item.product.ean}
                                        </td>
                                        <td data-label="Nome">
                                            {' '}
                                            <span>
                                                {item.product.description}
                                            </span>
                                        </td>
                                        <td data-label="Embalagem">
                                            {item.product.packing}
                                        </td>
                                        <td data-label="Quantidade">
                                            {item.quantity}
                                        </td>
                                        {item.campaign_item_id && (
                                            <td data-label="Preço UN">
                                                <span>
                                                    {Utils.formatPrice(
                                                        item.unitPrice
                                                    )}
                                                </span>
                                            </td>
                                        )}
                                        <td data-label="Preço Embalagem">
                                            <span>
                                                {Utils.formatPrice(
                                                    Number(item.packing_price)
                                                )}
                                            </span>
                                        </td>
                                        <td data-label="Total">
                                            <span>
                                                {Utils.formatPrice(
                                                    item.totalPrice
                                                )}
                                            </span>
                                        </td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

//export const DirectSaleOrderDetails: FC<any> = ({ order } : any) => (
//    <div className="container-fluid">
//        <div >
//            <div className={ `col col-3 ${styles.logo}` }>
//                <img src="/images/logo-sign-in.jpg" alt="logo" />
//                <Status status={ order.status } />
//            </div>
//            <div className={ `col col-6 ${styles.company}` }>
//                <table className="table">
//                    <tbody>
//                        <tr><td><strong>{ order.provider.name }</strong></td></tr>
//                        <tr><td>{ order.provider.socialReason }</td></tr>
//                        <tr><td><strong>CNPJ</strong> : { order.provider.cnpj }</td></tr>
//                        <tr>
//                            <td>
//                                <strong>Telefone</strong>: { order.provider.phone }
//                                <strong>Email</strong> : { order.provider.email }
//                            </td>
//                        </tr>
//                        <tr><td><strong>Bairro</strong>:  { order.provider.neighborhood }</td></tr>
//                        <tr>
//                            <td>
//                                <strong>Endereço</strong>: { order.provider.address }
//                                <strong>CEP</strong>: { order.provider.cep }
//                            </td>
//                        </tr>
//                    </tbody>
//                </table>
//            </div>
//            <div className={ `col col-3 mb-2 ${styles.company}` }>
//                <table className={ `table` }>
//                    <tbody>
//                        <tr>
//                            <td><strong>Pedido:</strong> { order.id }</td>
//                        </tr>
//                        <tr>
//                            <td><strong>Data:</strong>
//                                { DateService.formatToDisplayWithTime(new Date(order.date)) }
//                            </td>
//                        </tr>
//                        { order.deliveryDate && <tr>
//                            <td>
//                                <p>
//                                    <strong>Entrega: </strong>
//                                    { DateService.formatToDisplay(new Date(order.deliveryDate)) }
//                                </p>
//
//                            </td>
//                        </tr> }
//                        { order.campaign_id && <tr>
//                            <td>
//                                <p>
//                                    <strong>Campanha: (#{ order.campaign?.id }) </strong>
//                                    { order.campaign?.titulo }
//                                </p>
//                            </td>
//                        </tr> }
//                    </tbody>
//                </table>
//            </div>
//            <div className={ `col col-12 mb-2 ${styles.company}` }>
//                <table className='table'>
//                    <thead>
//                        <tr>
//                            <th colSpan={ 2 }>Cliente</th>
//                        </tr>
//                    </thead>
//                    <tbody>
//                        <tr>
//                            <td colSpan={ 2 }>{ order.name }</td>
//                            <td><strong>CNPJ</strong>: { order.lojaCondicao.cnpj }</td>
//                        </tr>
//                        <tr>
//                            <td><strong>Telefone</strong>: { order.lojaCondicao.phone }</td>
//                            <td colSpan={ 2 }><strong>Email</strong>: { order.lojaCondicao.email }</td>
//                        </tr>
//                        <tr>
//                            <td><strong>Bairro:</strong> { order.lojaCondicao.neighborhood } </td>
//                            <td>
//                                <strong>Endereço</strong>: { order.lojaCondicao.address }
//                            </td>
//                            <td>
//                                <strong>CEP</strong> { order.lojaCondicao.cep }
//                            </td>
//                        </tr>
//                    </tbody>
//                </table>
//            </div>
//            <div className={ styles.listDirectSalesOrderDetails }>
//                <table className={ styles.NewTableDirectSalesOrderDetails }>
//                    <thead>
//                        <tr>
//                            <th>EAN</th>
//                            <th>Nome</th>
//                            <th>Embalagem</th>
//                            <th>Quantidade</th>
//                            { order.campaign_id  && <th>Preço UN</th> }
//                            <th>Preço Embalagem</th>
//                            <th>Total</th>
//                        </tr>
//                    </thead>
//                    <tbody>
//                        { order.items.map((item: DirectSaleOrderItem, i: number) => (
//                            <tr key={ i }>
//                                <td>{ item.product.ean }</td>
//                                <td><span>{ item.product.description }</span></td>
//                                <td>{ item.product.packing }</td>
//                                <td>{ item.quantity }</td>
//                                { item.campaign_item_id
//                                    && <td><span>{ Utils.formatPrice(item.unitPrice) }</span></td> }
//                                <td><span>{ Utils.formatPrice(item.packing_price) }</span></td>
//                                <td><span>{ Utils.formatPrice(item.totalPrice) }</span></td>
//                            </tr>
//                        )) }
//                    </tbody>
//                </table>
//            </div>
//        </div>
//    </div>
//)
