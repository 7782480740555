import styled from 'styled-components'

export const Container = styled.div`
    padding: 23px 12px;
    font-family: 'Rubik';
`

//------------- tabs
export const ButtonTabsContainer = styled.div`
    flex-wrap: wrap;
    display: flex;
    //justify-content: space-between;
    gap: 15px;
    margin-bottom: 15px;
`

//------------- content
export const ContentContainer = styled.div`
    overflow: hidden;

    @media only screen and (max-width: 740px) {
        overflow: auto;
    }
`

// ---------- DATA

export const DataContentContainer = styled.div`
    margin-top: 15px;
    animation: 0.5s ease-out 0s fadeIn;

    @keyframes fadeIn {
        from {
            opacity: 0;
            transform: translate3d(-1%, 0, 0);
        }
        to {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
`

export const Row = styled.div`
    font-size: 14px;
    display: flex;
    justify-content: space-between;

    :nth-child(even) {
        background-color: #f7f7f7;
    }
    @media only screen and (max-width: 740px) {
        flex-wrap: wrap;

        :nth-child(even) {
            background-color: unset;
        }
    }
`
export const RowDescription = styled.div`
    align-items: center;
    padding: 12px 30px;
    width: 100%;
    display: flex;
    @media only screen and (max-width: 740px) {
        flex-direction: column;
        align-items: flex-start;

        :nth-child(even) {
            background-color: #f7f7f7;
        }
    }
`

export const InfoTitle = styled.p`
    min-width: 150px;
    font-weight: 400;
`
export const InfoDescription = styled.p`
    font-weight: 300;
`

/// -----------------PRODUCT
export const ProductContentContainer = styled.div`
    font-weight: 300;
    animation: 0.3s ease-out 0s 1 fadeInProduct;
    height: 100%;
    section {
        min-height: 400px;
        max-height: 90%;
        overflow-y: none;
    }
    @keyframes fadeInProduct {
        from {
            overflow: visible;
            opacity: 0;
            transform: translate3d(1%, 0, 0);
        }
        to {
            opacity: 1;
            overflow: auto;
            transform: translate3d(0, 0, 0);
        }
    }
`

export const FilialsContentContainer = styled.div`
    font-weight: 300;
    animation: 0.3s ease-out 0s 1 fadeInProduct;

    @keyframes fadeInProduct {
        from {
            overflow: visible;
            opacity: 0;
            transform: translate3d(1%, 0, 0);
        }
        to {
            opacity: 1;
            overflow: auto;
            transform: translate3d(0, 0, 0);
        }
    }
`
export const OrdersContentContainer = styled.div`
    font-weight: 300;
    animation: 0.3s ease-out 0s 1 fadeInProduct;

    @keyframes fadeInProduct {
        from {
            overflow: visible;
            opacity: 0;
            transform: translate3d(1%, 0, 0);
        }
        to {
            opacity: 1;
            overflow: auto;
            transform: translate3d(0, 0, 0);
        }
    }
`

/// ========== REFERENCES
export const ReferencesContainer = styled.div`
    animation: 0.3s ease-out 0s 1 fadeInProduct;

    section {
        min-height: 400px;
        max-height: 90%;
        overflow: none;
    }
`
