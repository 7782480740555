import { FunctionComponent } from 'react'
import { Button } from '../button'
import { Modal } from 'react-bootstrap'
import './index.scss'

const ModalComponent: FunctionComponent<any> = ({
    children,
    modal,
    show,
    className,
    footerNull,
    modalRegisterClient, // Pode ou não ser passado
    ...props
}: any) => {
    return (
        <Modal
            size="lg"
            show={show}
            onHide={props.close}
            animation={true}
            dialogClassName={className}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {modal.icon}
                    <span>
                        {modal.title}
                        <span className="subtitle">
                            {modal.subtitle && (
                                <>
                                    <br />
                                    {modal.subtitle}
                                </>
                            )}
                        </span>
                    </span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ overflow: 'auto' }}>
                <div className="content-fluid">{children}</div>
            </Modal.Body>
            {!footerNull && (
                <Modal.Footer>
                    {modalRegisterClient && (
                        <Button
                            variant="primary"
                            className="btn_register"
                            onClick={modalRegisterClient}
                        >
                            {modal.submit_text || 'Cadastrar'}
                        </Button>
                    )}
                    <Button variant="secondary" onClick={props.close}>
                        Voltar
                    </Button>
                    {modal && modal.submit && (
                        <Button variant="primary" onClick={modal.submit}>
                            {modal.submit_text || 'Salvar'}
                        </Button>
                    )}
                </Modal.Footer>
            )}
        </Modal>
    )
}

export default ModalComponent
