import { FC, useEffect, useState } from 'react'
import { Select } from 'components/select'
import UserService from 'services/UserService'
import ButtonV3 from 'components/Buttonv3'
import { BsSearch } from 'react-icons/bs'
import { IoMdTrash } from 'react-icons/io'
import './index.scss'

interface NewClientsFilterProps {
    onFilter: (selectedSegment: number | null) => void
    onClear: () => void
}

interface SegmentosProps {
    data: Ramo[]
}

type Ramo = {
    id: number
    name: string
}

export const NewClientsFilter: FC<NewClientsFilterProps> = ({
    onFilter,
    onClear,
}) => {
    const [selectedSegment, setSelectedSegment] = useState<number | null>(null)
    const [ramos, setRamos] = useState<SegmentosProps[]>([])

    useEffect(() => {
        const loadRamos = async () => {
            try {
                const data = await UserService.ramosDeAtividade()
                setRamos(data)
            } catch (e) {
                console.error(e)
            }
        }
        loadRamos()
    }, [])

    const handleFilter = () => {
        onFilter(selectedSegment)
    }

    const handleClear = () => {
        setSelectedSegment(null)
        onClear()
    }

    return (
        <div className="newClientsFilterContainer">
            <div className="filterItemRamo">
                <Select
                    className="labelSegmento"
                    description="Segmentos"
                    value={selectedSegment}
                    value_key="id"
                    label_key="name"
                    options={ramos}
                    blank_label="Selecione um segmento"
                    onChange={(e: any) =>
                        setSelectedSegment(Number(e.target.value))
                    }
                />
            </div>
            <div className="buttonsFilters">
                <ButtonV3
                    title="Limpar"
                    Icon={<IoMdTrash className="iconButtonV3" />}
                    onClick={handleClear}
                />
                <ButtonV3
                    Icon={<BsSearch className="iconButtonV3" color="#fff" />}
                    title="Filtrar"
                    filled
                    onClick={handleFilter}
                />
            </div>
        </div>
    )
}
