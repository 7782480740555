import { ButtonTab } from 'components/buttonTab'
import { DescriptionRow } from 'components/descriptionRow'
import { Loader } from 'components/loader'
import { PaginationCustom } from 'components/pagination-custom'
import { PhoneWithWhats } from 'components/phoneWithWhats'
import { mask_cnpj } from 'helpers/mask.helper'
import React, { useLayoutEffect, useState, useEffect } from 'react'
import { MdOutlineStore, MdOutlineAssignment } from 'react-icons/md'
import ClientService from 'services/v3/clients.service'

import {
    Container,
    ButtonTabsContainer,
    ContentContainer,
    Row,
    RowDescription,
    InfoTitle,
    InfoDescription,
    DataContentContainer,
    ProductContentContainer,
    FilialsContentContainer,
    ReferencesContainer,
    OrdersContentContainer,
} from './styles'
import { FiShoppingCart } from 'react-icons/fi'
import { TableOrderCot } from './components/tableOrderCot'
import OrderService from 'services/OrderService'
import { PaginationCustomV3 } from 'components/pagination-customerV3'
import { FeedbackError } from 'components/feedback/error'
import CardInfo from '../../../../components/cardInfo'

interface props {
    data: any
    cliId?: any
}
//!adicionei CLiiD para utilizaçao do modal na tela de cotações

export function CompanyDetailsModal({ data, cliId }: props) {
    const [showtab, setShowTab] = useState('DATA')
    const [requestedProducts, setRequestedProducts] = useState<any>()
    const [comercialsReferences, setComercialsReferences] = useState<any>()
    const [loading, setLoading] = useState(false)
    const [loadingPage, setLoadingPage] = useState(false)
    const [ordersCot, setOrdersCot] = useState<any>()
    const [loadingOrders, setLoadingOrders] = useState(false)

    const [paramsOrders, setParamsOrders] = useState({
        page: 1,
        cliente: cliId ?? data.id,
    })

    useEffect(() => {
        setLoading(true)
        ClientService.getRequestedProducts(cliId ?? data.id)
            .then((res) =>
                // ClientService.getRequestedProducts(371).then(res =>
                setRequestedProducts(res)
            )
            .then((res) => setLoading(false))
        ClientService.getComercialsReferences(cliId ?? data.id).then((res) =>
            // ClientService.getComercialsReferences(371).then(res =>
            setComercialsReferences(res)
        )
    }, [])

    useEffect(() => {
        fetchOrdersCot()
    }, [paramsOrders])

    function fetchOrdersCot() {
        setLoadingOrders(true)

        OrderService.getAll(paramsOrders)
            .then((res) => {
                setOrdersCot(res)
            })
            .catch((e) => console.error(e))
            .finally(() => setLoadingOrders(false))
    }

    function handleOrdersPageChange(page: number) {
        setParamsOrders((prev) => ({
            ...prev,
            page,
        }))
    }

    function handlePageChange(page) {
        setLoadingPage(true)
        ClientService.getRequestedProducts(data.id, page)
            .then((res) => setRequestedProducts(res))
            .then((res) => setLoadingPage(false))
    }

    function formatToBRLCurrency(value) {
        if (typeof value !== 'number') return 'R$ 0,00' // Tratamento caso não seja número
        return value.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        })
    }

    function getContent() {
        let content
        switch (showtab) {
            case 'DATA':
                content = dataContent()
                break

            case 'PRODUCT':
                content = ProductContent()
                break
            case 'REFERENCES':
                content = referencesContent()
                break
            case 'FILIALS':
                content = filialsContent()
                break
            case 'ORDERCOT':
                content = orderCotContent()
                break
            default:
                content = <></>
                break
        }
        return content
    }

    function dataContent() {
        return (
            <DataContentContainer className={'fadeIn'}>
                <Row>
                    <RowDescription>
                        <InfoTitle>CNPJ:</InfoTitle>
                        <InfoDescription>
                            {data.head_office?.cnpj &&
                                mask_cnpj(data.head_office.cnpj)}
                        </InfoDescription>
                    </RowDescription>
                    <RowDescription>
                        <InfoTitle>Email:</InfoTitle>
                        <InfoDescription>
                            {data.head_office?.email}
                        </InfoDescription>
                    </RowDescription>
                </Row>
                <Row>
                    <RowDescription>
                        <InfoTitle>Razão Social:</InfoTitle>
                        <InfoDescription
                            style={{ 'text-transform': 'uppercase' }}
                        >
                            {data.head_office?.social_reazon}
                        </InfoDescription>
                    </RowDescription>
                    <RowDescription>
                        <InfoTitle>Grupo:</InfoTitle>
                        <InfoDescription
                            style={{ 'text-transform': 'uppercase' }}
                        >
                            {data?.group_name}
                        </InfoDescription>
                    </RowDescription>
                </Row>
                <Row>
                    <RowDescription>
                        <InfoTitle>Nome Fantasia:</InfoTitle>
                        <InfoDescription
                            style={{ 'text-transform': 'uppercase' }}
                        >
                            {data.head_office?.trading_name}
                        </InfoDescription>
                    </RowDescription>
                    <RowDescription>
                        <InfoTitle>Cidade:</InfoTitle>
                        <InfoDescription>
                            {data.head_office?.city.name}
                        </InfoDescription>
                    </RowDescription>
                </Row>
                <Row>
                    <RowDescription>
                        <InfoTitle>Nº de empresas:</InfoTitle>
                        <InfoDescription>
                            {data.filials?.length
                                ? data.filials?.length == 0
                                    ? '1'
                                    : data.filials?.length
                                : '1'}
                        </InfoDescription>
                    </RowDescription>
                    <RowDescription>
                        <InfoTitle>Endereço:</InfoTitle>
                        <InfoDescription>
                            {data.head_office?.street}
                        </InfoDescription>
                    </RowDescription>
                </Row>
                <Row>
                    <RowDescription>
                        <InfoTitle>Telefone:</InfoTitle>
                        <InfoDescription>
                            <PhoneWithWhats phone={data.head_office?.phone} />
                        </InfoDescription>
                    </RowDescription>
                    <RowDescription>
                        <InfoTitle>Bairro:</InfoTitle>
                        <InfoDescription>
                            {data.head_office?.district}
                        </InfoDescription>
                    </RowDescription>
                </Row>
                <Row>
                    <RowDescription>
                        <InfoTitle>Contato:</InfoTitle>
                        <InfoDescription>{data?.contact}</InfoDescription>
                    </RowDescription>
                    <RowDescription>
                        <InfoTitle>CEP:</InfoTitle>
                        <InfoDescription>
                            {data.head_office?.zip_code}
                        </InfoDescription>
                    </RowDescription>
                </Row>
            </DataContentContainer>
        )
    }
    function ProductContent() {
        return loading ? (
            <Loader />
        ) : (
            <ProductContentContainer>
                <section className="NewlistProducts">
                    <table className={'newTableProducts'}>
                        <thead style={{}}>
                            <tr>
                                <th style={{ width: '10%' }}>id</th>
                                <th style={{ width: '75%' }}>Produto</th>
                                <th style={{ width: '15%' }}>Embalagem</th>
                            </tr>
                        </thead>
                        {loadingPage && <Loader />}
                        <tbody>
                            {requestedProducts &&
                                requestedProducts.data?.map((item): any => (
                                    <tr>
                                        <td data-label="id">
                                            {item.pro_ean
                                                ? item.pro_ean
                                                : '- -'}
                                        </td>
                                        <td data-label="Produto">
                                            {item.pro_descricao}
                                        </td>
                                        <td data-label="Embalagem">
                                            {item.pro_generico == 1
                                                ? item.pro_unidade_compra
                                                : item.pro_embalagem}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </section>
                {requestedProducts && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                            marginTop: 15,
                        }}
                    >
                        <div style={{ width: '60%' }}>
                            <PaginationCustomV3
                                totalItems={
                                    requestedProducts
                                        ? requestedProducts.total
                                        : 0
                                } // Total de itens, por exemplo, 2890
                                currentPage={requestedProducts.current_page} // A página atual
                                handlePageChange={handlePageChange}
                                itemsPerPage={requestedProducts.per_page} // Número de itens por página
                                itemsName="produtos"
                            />
                        </div>
                    </div>
                )}
            </ProductContentContainer>
        )
    }
    function referencesContent() {
        return (
            <ReferencesContainer>
                <section className="NewlistProducts">
                    <table className={'newTableProducts'}>
                        <thead style={{}}>
                            <tr>
                                <th>Empresa</th>
                                <th>CNPJ</th>
                                <th>Estado</th>
                                <th>Cidade</th>
                            </tr>
                        </thead>

                        <tbody>
                            {comercialsReferences &&
                                comercialsReferences.map((item): any => (
                                    <tr>
                                        <td data-label="Empresa">
                                            {item.for_razaoSocial
                                                ? item.for_razaoSocial
                                                : ''}
                                        </td>
                                        <td data-label="CNPJ">
                                            {item.for_cnpj
                                                ? mask_cnpj(item.for_cnpj)
                                                : ''}
                                        </td>
                                        <td data-label="Estado">
                                            {item.est_nome ? item.est_nome : ''}
                                        </td>
                                        <td data-label="Cidade">
                                            {item.cid_nome ? item.cid_nome : ''}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                    {
                        //    <PaginationCustom
                        //        total={allPages}
                        //        itemsName={'produtos'}
                        //        ItemsPorPage={pageSize}
                        //        currentPage={currentPage}
                        //        onPageChange={handlePageChange}
                        //        totalItems={products ? products.length : 0}
                        //    />
                    }
                </section>
            </ReferencesContainer>
        )
    }
    function filialsContent() {
        return (
            <FilialsContentContainer>
                <div className="NewlistProducts">
                    <table className={'newTableProducts'}>
                        <thead>
                            <tr>
                                <th>Razão social</th>
                                <th>Nome fantasia</th>
                                <th>CNPJ</th>
                                <th>Cidade</th>
                                <th>UF</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data &&
                                data.filials?.map((item: any) => (
                                    <tr>
                                        <td data-label="Razão social">
                                            {item.social_reazon}
                                        </td>
                                        <td data-label="Nome fantasia">
                                            {item.trading_name}
                                        </td>
                                        <td data-label="CNPJ">{item.cnpj}</td>
                                        <td data-label="Cidade">
                                            {item.city.name}
                                        </td>
                                        <td data-label="UF">{item.state.fu}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                    {
                        //    <PaginationCustom
                        //        total={allPages}
                        //        itemsName={'produtos'}
                        //        ItemsPorPage={pageSize}
                        //        currentPage={currentPage}
                        //        onPageChange={handlePageChange}
                        //        totalItems={products ? products.length : 0}
                        //    />
                    }
                </div>
            </FilialsContentContainer>
        )
    }

    function orderCotContent() {
        return (
            <OrdersContentContainer>
                {loadingOrders ? (
                    <Loader />
                ) : (
                    <>
                        {ordersCot && ordersCot.total > 0 ? (
                            <>
                                <div>
                                    <CardInfo
                                        quantity={formatToBRLCurrency(
                                            ordersCot.totalValue
                                        )}
                                        title="Valor total dos pedidos"
                                        variant="blue"
                                    />
                                </div>

                                <TableOrderCot ordersCot={ordersCot} />

                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        width: '100%',
                                        marginTop: 15,
                                    }}
                                >
                                    <div style={{ width: '60%' }}>
                                        <PaginationCustomV3
                                            totalItems={ordersCot.total} // Total de itens, por exemplo, 2890
                                            currentPage={ordersCot.current_page} // A página atual
                                            handlePageChange={
                                                handleOrdersPageChange
                                            }
                                            itemsPerPage={ordersCot.per_page} // Número de itens por página
                                            itemsName="pedidos"
                                        />
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div
                                style={{
                                    textAlign: 'center',
                                    marginTop: '20px',
                                    fontSize: '16px',
                                    color: '#888',
                                }}
                            >
                                <FeedbackError
                                    message={
                                        'Nenhum registro de pedidos encontrado.'
                                    }
                                />
                            </div>
                        )}
                    </>
                )}
            </OrdersContentContainer>
        )
    }

    return (
        <Container>
            <ButtonTabsContainer>
                <ButtonTab
                    isActive={showtab === 'DATA'}
                    title={'Dados Principais'}
                    icon={<MdOutlineAssignment />}
                    onClick={() => setShowTab('DATA')}
                />
                <ButtonTab
                    isActive={showtab === 'PRODUCT'}
                    title={'Produtos Cotados'}
                    icon={<MdOutlineAssignment />}
                    onClick={() => setShowTab('PRODUCT')}
                />
                <ButtonTab
                    isActive={showtab === 'REFERENCES'}
                    title={'Referências Comerciais'}
                    icon={<MdOutlineAssignment />}
                    onClick={() => setShowTab('REFERENCES')}
                />
                <ButtonTab
                    isActive={showtab === 'FILIALS'}
                    title={'Filiais'}
                    icon={<MdOutlineStore />}
                    onClick={() => setShowTab('FILIALS')}
                />
                <ButtonTab
                    isActive={showtab === 'ORDERCOT'}
                    title={'Pedidos da Cotação'}
                    icon={<FiShoppingCart />}
                    onClick={() => setShowTab('ORDERCOT')}
                />
            </ButtonTabsContainer>

            <ContentContainer>{getContent()}</ContentContainer>
        </Container>
    )
}
