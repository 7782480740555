import { IoStorefrontOutline } from 'react-icons/io5'
import styles from './style.module.scss'
import { Rating } from 'components/rating'
import { Order } from 'interfaces/order'
import { useState } from 'react'
import {
    Status,
    STATUS_CONFIRMED,
    STATUS_NOT_CONFIRMED,
} from 'components/status'
import DateService from 'services/DateService'
import Utils from 'utils'
import { RatingCommentsModal } from 'pages/orders/components/rating-comments'

interface ordersCotProps {
    ordersCot: OrderCot
}

type OrderCot = {
    data: Order[]
    current_page: number
    from: number
    last_page: number
    per_page: number
    to: number
    total: number
    totalValue: number
}

export function TableOrderCot({ ordersCot }: ordersCotProps) {
    const [show_comments, open_comments_modal] = useState<boolean>(false)
    const [orderId, setOrderId] = useState<number>()

    const open_comments = (id) => {
        setOrderId(id)
        open_comments_modal(true)
    }

    return (
        <div className={styles.NewListOrders}>
            <table className={styles.newTableOrders}>
                <thead>
                    <tr>
                        <th>Pedido</th>
                        <th>Avaliação</th>
                        <th>Empresa</th>
                        <th>Data</th>
                        <th>Cotação</th>
                        <th>Valor do Pedido</th>
                        <th>Status</th>
                        <th>Vendedor</th>
                    </tr>
                </thead>
                <tbody>
                    {ordersCot.data.map((item: Order) => (
                        <tr className={styles.trMobile} key={item.id}>
                            <td
                                style={{ padding: 0 }}
                                className={styles.showOnlyInMobile}
                                data-label=""
                            >
                                <div className={styles.storeNameMobile}>
                                    <IoStorefrontOutline />
                                    <p>{item.storeName}</p>
                                </div>
                            </td>
                            <td
                                className={styles.showOnlyInMobile}
                                data-label=""
                            >
                                <div className={styles.rowIdStatus}>
                                    <p>
                                        <span>Pedido: </span>#{item.id}
                                    </p>
                                    <p>
                                        <span>Cotação: </span>#{item.id}
                                    </p>
                                    <p>
                                        <Status
                                            status={
                                                item.status === 'C'
                                                    ? STATUS_CONFIRMED
                                                    : STATUS_NOT_CONFIRMED
                                            }
                                        />
                                    </p>
                                </div>
                            </td>
                            <td
                                className={styles.hideOnMobile}
                                data-label="Pedido"
                            >
                                <p>#{item.id}</p>
                            </td>
                            <td
                                className={styles.hideOnMobile}
                                data-label="Avaliação"
                            >
                                <Rating
                                    rate={item.rate}
                                    onClick={() => open_comments(item.id)}
                                />
                            </td>

                            <td
                                className={styles.hideOnMobile}
                                data-label="Empresa"
                            >
                                {item.storeName}
                            </td>
                            <td
                                className={styles.hideOnMobile}
                                data-label="Data"
                            >
                                {DateService.formatToDisplayWithTime(
                                    new Date(item.date)
                                )}
                            </td>
                            <td
                                className={styles.hideOnMobile}
                                data-label="Cotação"
                            >
                                <p>#{item.quotationId}</p>
                            </td>
                            <td
                                style={{ fontWeight: 500 }}
                                data-label="Valor do Pedido"
                            >
                                {Utils.formatPrice(item.value)}
                            </td>
                            <td
                                className={styles.hideOnMobile}
                                data-label="Status"
                            >
                                <Status
                                    status={
                                        item.status === 'C'
                                            ? STATUS_CONFIRMED
                                            : STATUS_NOT_CONFIRMED
                                    }
                                />
                            </td>
                            <td data-label="Vendedor">{item.salesmanName}</td>

                            <td
                                className={styles.showOnlyInMobile}
                                data-label=""
                            >
                                <div
                                    style={{
                                        justifyContent: 'space-between',
                                        gap: 4,
                                    }}
                                    className={styles.actions}
                                >
                                    <p>
                                        {DateService.formatToDisplayWithTime(
                                            new Date(item.date)
                                        )}
                                    </p>
                                    <div className={styles.ratingOnActions}>
                                        <Rating
                                            size={25}
                                            rate={item.rate}
                                            onClick={() => open_comments(item)}
                                        />
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {ordersCot && show_comments && (
                <RatingCommentsModal
                    onCloseCallback={() => open_comments_modal(false)}
                    id={orderId}
                    isVisible={ordersCot && show_comments}
                />
            )}
        </div>
    )
}
