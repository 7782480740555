import { useState, useEffect } from 'react'
import { IoIosCloseCircle } from 'react-icons/io'
import './index.scss'
import { RiAddCircleFill } from 'react-icons/ri'

interface ModalProps {
    isOpen: any
    onClose: () => void
    title: string
    children: React.ReactNode
}

export function Modal({ isOpen, onClose, title, children }: ModalProps) {
    const [visible, setVisible] = useState(isOpen)

    useEffect(() => {
        if (isOpen) {
            setVisible(true)
        } else {
            const timer = setTimeout(() => {
                setVisible(false)
            }, 500)
            return () => clearTimeout(timer)
        }
    }, [isOpen])

    return (
        visible && (
            <div
                className={`custom-modal-overlay ${
                    !isOpen ? 'custom-modal-overlay-hidden' : ''
                }`}
            >
                <div
                    className={`custom-modal-box ${
                        !isOpen ? 'custom-modal-hidden' : ''
                    }`}
                >
                    <div className="custom-modal-header">
                        <RiAddCircleFill /> <h2>{title}</h2>
                        <button
                            className="custom-modal-close-button"
                            onClick={onClose}
                        >
                            <IoIosCloseCircle />
                        </button>
                    </div>
                    <div className="custom-modal-content">{children}</div>
                </div>
            </div>
        )
    )
}
