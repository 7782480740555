import RepresentativesService from '../../services/RepresentativesService'
// import { Representative } from '../../interfaces/representative';

export const LOAD_REPRESENTATIVES_SUCCESS = 'LOAD_REPRESENTATIVES_SUCCESS'

const initialState = null

export default function representatives(state = initialState, action: any) {
    if (action.type === LOAD_REPRESENTATIVES_SUCCESS) {
        return action.representatives
    }
    return state
}

export const loadRepresentatives = (params: any) => (dispatch: any) =>
    RepresentativesService.getAll(params)
        .then((response: any[]) => {
            dispatch(setRepresentatives(response))
        })
        .catch(() => dispatch(setRepresentatives(null)))

// export const loadRepresentatives = (params: any) => (dispatch: any) => {
//     if (params.vendedor) {
//         RepresentativesService.getById(params.vendedor)
//             .then((response: any) => {
//                 dispatch(setRepresentatives(response))
//                 console.log(response)
//             })
//             .catch(() => dispatch(setRepresentatives(null)))
//     } else {
//         RepresentativesService.getAll(params)
//             .then((response: any[]) => {
//                 dispatch(setRepresentatives(response))
//             })
//             .catch(() => dispatch(setRepresentatives(null)))
//     }
// }

const setRepresentatives = (representatives: any[] | null) => ({
    type: LOAD_REPRESENTATIVES_SUCCESS,
    representatives,
})
