/* eslint-disable react/jsx-curly-spacing */
import React, { FC } from 'react'
import { Label } from '../label'
import InputMask from 'react-input-mask'
import './index.scss'
import { IoMdInformationCircle } from 'react-icons/io'

export const Input: FC<any> = ({
    mask,
    mode,
    register,
    infoIconNull,
    ...props
}: any) => (
    <div className={mode === 'search' ? 'c-search-group' : 'default'}>
        {props.description && (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: ' center',
                    marginBottom: 4,
                }}
            >
                <Label
                    style={{ marginBottom: '0px !important' }}
                    className="c-label"
                >
                    {props.description}
                </Label>
                {infoIconNull == false && (
                    <section
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: 3,
                        }}
                    >
                        <IoMdInformationCircle className="icon" />
                    </section>
                )}
            </div>
        )}
        {mask ? (
            <InputMask mask={mask} {...props}>
                {(inputProps) => (
                    <input
                        type="text"
                        ref={register}
                        {...inputProps}
                        className={`c-input form-control ${props.className}`}
                    />
                )}
            </InputMask>
        ) : (
            <>
                {register ? (
                    <input
                        style={{ outline: 'red', outlineWidth: 20 }}
                        type="text"
                        ref={register}
                        {...props}
                        className={`c-input form-control ${props.className}`}
                    />
                ) : (
                    <input
                        type="text"
                        {...props}
                        className={`c-input form-control ${props.className}`}
                    />
                )}
                {mode === 'search' && (
                    <i className="c-search-icon search-icon material-icons p-absolute">
                        search
                    </i>
                )}
            </>
        )}
    </div>
)
