import QueryString from 'qs'
import HttpClient from './http.client'

interface SaveConditionRequest {
    store_id: Number
    sol_id: Number
    is_loja: Number
    vendedor_id: Number
}

export default class ClientService {
    static load_stores = async (params: any): Promise<any> => {
        return HttpClient.get(`/providers/clients`, {
            ...params,
            // sort: 'l.loj_razao',
        })
    }

    static load_clients_condition = async (params: any): Promise<any> => {
        const queryString = new URLSearchParams(params).toString()
        return HttpClient.get(
            `/providers/clients/clients_condition?${queryString}`
        )
    }

    static delete_store = (id: number): Promise<any> => {
        return HttpClient.post(`/clients/seller/condition/${id}`, {})
    }
    static updateCondition = (conditionId: number, data) => {
        HttpClient.put(`/providers/condition/${conditionId}`, data)
    }

    static saveCondition = ({
        store_id,
        sol_id,
        is_loja,
        vendedor_id,
    }: SaveConditionRequest) => {
        return HttpClient.post(`/providers/condition/`, {
            loj_id: store_id,
            sol_id: sol_id,
            is_loja: is_loja,
            vendedor_id: vendedor_id,
        })
    }

    static sellers = async (id: number): Promise<any> => {
        return HttpClient.get(`/providers/clients/${id}/sellers`, {
            stype: 'REQUEST',
        })
    }

    static blacklist = async (): Promise<any> => {
        return HttpClient.get(`/providers/clients/blacklisted`)
    }

    static deactivate = async (id: number, seller_id: number): Promise<any> => {
        return HttpClient.delete(
            `/providers/clients/${id}/sellers/${seller_id}/deactivate`
        )
    }

    static discontinue_product = async (
        client_id: number,
        product_id: number,
        seller_id: number
    ): Promise<any> => {
        return HttpClient.patch(
            `/providers/clients/${client_id}/products/${product_id}/discontinue?seller_id=${seller_id}`,
            {}
        )
    }
    static continue_product = async (
        client_id: number,
        product_id: number,
        seller_id: number
    ): Promise<any> => {
        return HttpClient.patch(
            `/providers/clients/${client_id}/products/${product_id}/continue?seller_id=${seller_id}`,
            {}
        )
    }
    static getRequestedProducts = async (
        client_id: number,
        page?: ''
    ): Promise<any> => {
        return HttpClient.get(
            `/providers/products/cotados?cli_id=${client_id}&page=${page}`
        )
    }
    static getComercialsReferences = async (
        client_id: number
    ): Promise<any> => {
        return HttpClient.get(
            `/providers/products/referencias/comerciais?cli_id=${client_id}`
        )
    }
}
