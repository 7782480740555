import IStoreCondition from 'interfaces/storeCondition'

export default class StoreConditionService {
    static parse = (storeFromApi: any): IStoreCondition => ({
        id: storeFromApi.loj_id,
        clientId: storeFromApi.for_id,
        name: storeFromApi.nome_fantasia,
        email: storeFromApi.loj_email,
        cnpj: storeFromApi.CNPJ,
        phone: storeFromApi.numero,
        neighborhood: storeFromApi.bairro,
        address: storeFromApi.endereco,
        cep: storeFromApi.cep,
        socialReason: storeFromApi.razao_social || '',
        contactName: storeFromApi.nome_contato || '',
        segment: storeFromApi.seg_id || '',
        count_condition: storeFromApi.count_condicao_loja,
        isAcceptingPix: storeFromApi.is_accepting_pix === 1,
        deliveryAvailable: storeFromApi.realiza_entrega === 1,
        deliveryDate: storeFromApi.data_entrega,
        minDeliveryOrder: storeFromApi.fat_min_entrega,
        minPickupOrder: storeFromApi.fat_min_retirada,
        routeId: storeFromApi.rota_id,
        vendorId: storeFromApi.vendedor_id,
        allowsFractionalPurchase: storeFromApi.compra_fracionada === 1,
        groupId: storeFromApi.grupo_id,
        stateCode: storeFromApi.cod_estado,
        cityCode: storeFromApi.cod_cidade,
        additionalInfo: storeFromApi.complemento,
    })
}
