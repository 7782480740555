import { FC } from 'react'
import styles from './index.module.scss'

interface InfoParticipationCardProps {
    title: string
    quantity: number
    variant: 'orange' | 'green' | 'blue' | 'purple' | 'gray' | 'black' | string
}

const InfoParticipationCard: FC<InfoParticipationCardProps> = ({
    title,
    quantity,
    variant,
}) => {
    return (
        <div className={`${styles.statusCard} ${styles[variant]}`}>
            <span className={styles.title}>{title}</span>
            <span className={styles.quantity}>({quantity})</span>
        </div>
    )
}

export default InfoParticipationCard
