import React, { FC, useContext, useEffect, useState } from 'react'
import { Loader } from 'components/loader'
import { Select } from 'components/select'
import CustomerService from 'services/CustomerService'
import OrderService from 'services/OrderService'
import DateService from 'services/DateService'
import './index.scss'
import {
    STATUS_CONFIRMED,
    STATUS_CANCELED,
    STATUS_WAITING_CONFIRMAION,
    STATUS_STARTED,
} from 'components/status'
import { FilterComponent } from 'components/filter'
import { connect } from 'react-redux'
import { loadSalesmen } from 'store/salesman'
import { load_routes } from 'store/routes'
import { Datepicker } from 'components/datepicker'
import { UserContext } from 'contexts/user'
import { Input } from 'components/input'
import {
    AiOutlineClear,
    AiOutlineSearch,
    AiOutlineCloseCircle,
} from 'react-icons/ai'
import { MdPictureAsPdf } from 'react-icons/md'
import ButtonV3 from 'components/Buttonv3'
import InputV2 from 'components/InputV2'
import { ToastContainer, toast } from 'react-toastify'
import { CheckBoxCustom } from 'components/checkBoxCustom'
import { SiMicrosoftexcel } from 'react-icons/si'
import { BaseModal } from 'components/BaseModal'
import DownloadExcelModal from '../../../orders/components/downloadExcelModal'
import ProviderService from 'services/v3/ProviderService'

const DirectOrdersFilterComponent: FC<any> = ({
    onChange,
    sellers,
    routes,
    load_sellers,
    load_routes,
    locales,
}: any) => {
    const endDate: Date = new Date()
    const initialDate: Date = DateService.removeDaysToDate(
        new Date(endDate),
        10
    )
    const { user, can } = useContext(UserContext)
    const [loading, setLoading] = useState<boolean>(false)
    const [showInicio, setShowInicio] = useState<boolean>(false)
    const [showFim, setShowFim] = useState<boolean>(false)

    const statuses = [
        { value: STATUS_CONFIRMED, label: 'Confirmado' },
        { value: STATUS_WAITING_CONFIRMAION, label: 'Aguardando confirmação' },
        { value: STATUS_STARTED, label: 'Iniciado' },
        { value: STATUS_CANCELED, label: 'Cancelado' },
    ]

    const adjustDateToStartOrEndOfDay = (dateString, isEndOfDay = false) => {
        const dateObj = new Date(dateString)

        if (isEndOfDay) {
            dateObj.setHours(23, 59, 59, 999)
        } else {
            dateObj.setHours(0, 0, 0, 0)
        }

        const localISOString = dateObj.toLocaleString('sv-SE')
        return localISOString
    }

    const initialQueryParams = {
        page: 1,
        datainicial: adjustDateToStartOrEndOfDay(initialDate, false),
        status: '',
        cliente: '',
        paymentStatus: null,
        seller_id: '',
        offerId: null,
        id: null,
        uf: null,
        integration_field: null,
        status_control: null,
        id_local: null,
    }

    const [params, setParams] = useState<any>(initialQueryParams)
    const [customers, setCustomers] = useState<any>([])

    const update = (params: any) => {
        let revisedParams = { ...params }

        if (revisedParams.id != null && revisedParams.id != '') {
            revisedParams.datainicial = null
        }

        setParams((prevParams) => {
            const newParams = { ...prevParams, ...revisedParams }
            onChange(newParams)
            return newParams
        })
    }

    const pdf = async () => {
        try {
            setLoading(true)

            // if (params.seller_id == "") {
            //     params.seller_id = user.id
            // }
            // console.log('<',params)
            if (!can('READ_ALL', 'DIRECT_PURCHASE')) {
                params.seller_id = user?.id
            }
            // console.log('>',params)
            await OrderService.pdf({ ...params })

            setLoading(false)
        } catch (e) {
            // console.log(e);
            setLoading(false)
            alert('error')
        }

        //setLoading(false);
    }

    const clearFilters = () => {
        onChange(initialQueryParams)
        setParams(initialQueryParams)
        setShowFim(false)
        setShowInicio(false)
    }

    useEffect(() => {
        CustomerService.getAll(1).then((data) => setCustomers(data.data))
    }, [])

    useEffect(() => {
        if (user) {
            load_routes(user.providerId)
            load_sellers()
        }
    }, [user, load_routes, load_sellers])

    const updateDate = (date: string) => {
        if (date) {
            setParams({
                ...params,
                datainicial: date,
            })
        }
    }
    // function handleOpenExcelDownloadModal() {
    //     console.log('excel')
    //     setIsExcelModalVisible(true)
    // }

    return (
        <div>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {
                //   <FilterComponent clear={clearFilters} pdf={pdf} filter={() => update(params)} title="Filtrar Pedidos">
            }

            {loading && <Loader />}

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    gap: 15,
                }}
            >
                {/* <ButtonV3
                    title='Baixar planilha'
                    Icon={<SiMicrosoftexcel className='iconButtonV3' />}
                    onClick={() =>handleOpenExcelDownloadModal()}
                >
                </ButtonV3> */}
                <ButtonV3
                    title="PDF"
                    Icon={<MdPictureAsPdf className="iconButtonV3" />}
                    onClick={() => pdf()}
                ></ButtonV3>
            </div>
            {/* <SiMicrosoftexcel color='#17A2B8'
                                                                style={{ 'fontSize': 20, 'cursor': 'pointer' }}
                                                                onClick={() => handleOpenExcelDownloadModal(item)}
                                                                title="Importar planilha"
                                                            /> */}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    gap: 10,
                    marginBottom: 20,
                }}
            >
                <div className="filtersDirectSalesOrderDiv">
                    <div style={{}}>
                        {
                            //    <Input
                            //        description="ID"
                            //        placeholder="ID do pedido"
                            //        onChange={(e: any) => setParams({ ...params, id: e.target.value })}
                            //    />
                        }
                        <InputV2
                            infoIconNull
                            icon={
                                <AiOutlineSearch color="#898989"></AiOutlineSearch>
                            }
                            mask={undefined}
                            id={'ID'}
                            label={'ID'}
                            value={params.numerocotacao}
                            type={'text'}
                            placeholder={'ID do pedido'}
                            onChange={(e: any) =>
                                setParams({ ...params, id: e.target.value })
                            }
                        />
                    </div>
                    <div className="datePickerDirectSalesOrder">
                        {/* <ButtonV3

                            title='Selecionar'
                            onClick={() => update(params)}
                        /> */}
                        <Datepicker
                            callendarIcon
                            description="Data emissão"
                            value={params.datainicial}
                            onChange={(date: string) => {
                                const dateISO = adjustDateToStartOrEndOfDay(
                                    date,
                                    false
                                )
                                updateDate(dateISO)
                            }}
                        />
                    </div>
                    <div
                        style={{ width: 185 }}
                        className="datePickerDirectSalesOrder"
                    >
                        {params.delivery_from == null && !showInicio ? (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    height: 80,
                                }}
                            >
                                <p
                                    style={{
                                        fontFamily: 'Rubik',
                                        fontSize: 20,
                                        color: '#898989',
                                    }}
                                >
                                    Data entrega início
                                </p>
                                <ButtonV3
                                    style={{ height: 50 }}
                                    textStyle={{ color: '#898989' }}
                                    title="Selecionar"
                                    onClick={() => {
                                        setShowInicio(true)
                                        if (!params.delivery_from) {
                                            const todayISO =
                                                adjustDateToStartOrEndOfDay(
                                                    new Date().toISOString()
                                                )
                                            setParams((prev) => ({
                                                ...prev,
                                                delivery_from: todayISO,
                                            }))
                                        }
                                    }}
                                />
                            </div>
                        ) : (
                            <Datepicker
                                callendarIcon
                                value={params.delivery_from}
                                showNullDate={true}
                                description="Data entrega início"
                                onChange={(date: string) => {
                                    const dateISO = adjustDateToStartOrEndOfDay(
                                        date,
                                        false
                                    ) // false para início do dia
                                    setParams((prev) => ({
                                        ...prev,
                                        delivery_from: dateISO,
                                    }))
                                }}
                            />
                        )}
                    </div>

                    <div
                        style={{ width: 165 }}
                        className="datePickerDirectSalesOrder"
                    >
                        {params.delivery_to == null && !showFim ? (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    height: 80,
                                }}
                            >
                                <p
                                    style={{
                                        fontFamily: 'Rubik',
                                        fontSize: 20,
                                        color: '#898989',
                                    }}
                                >
                                    Data entrega fim
                                </p>
                                <ButtonV3
                                    style={{ height: 50 }}
                                    textStyle={{ color: '#898989' }}
                                    title="Selecionar"
                                    onClick={() => {
                                        setShowFim(true)
                                        if (!params.delivery_to) {
                                            const todayISO =
                                                adjustDateToStartOrEndOfDay(
                                                    new Date().toISOString(),
                                                    true
                                                ) // true para fim do dia
                                            setParams((prev) => ({
                                                ...prev,
                                                delivery_to: todayISO,
                                            }))
                                        }
                                    }}
                                />
                            </div>
                        ) : (
                            <Datepicker
                                callendarIcon
                                value={params.delivery_to}
                                description="Data entrega fim"
                                onChange={(date: string) => {
                                    const dateISO = adjustDateToStartOrEndOfDay(
                                        date,
                                        true
                                    ) // true para fim do dia
                                    setParams((prev) => ({
                                        ...prev,
                                        delivery_to: dateISO,
                                    }))
                                }}
                            />
                        )}
                    </div>
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-end',

                        flexWrap: 'wrap',
                        marginBottom: 10,
                    }}
                >
                    <div style={{ marginRight: 20 }}>
                        <ButtonV3
                            Icon={
                                <AiOutlineCloseCircle className="iconButtonV3" />
                            }
                            title="Limpar filtros"
                            onClick={() => clearFilters()}
                        />
                    </div>
                    <div>
                        <ButtonV3
                            filled
                            title="Filtrar"
                            onClick={() => update(params)}
                        />
                    </div>
                </div>
            </div>

            <div className="directSalesOrderDivInputsSelects">
                <div className="directSalesOrderItemInputsSelects">
                    <Input
                        description="Cliente"
                        placeholder="Selecione um cliente"
                        onChange={(e: any) =>
                            setParams({ ...params, name: e.target.value })
                        }
                    />
                </div>

                <div className="directSalesOrderItemInputsSelects">
                    <Select
                        style={{}}
                        description="Vendedor"
                        blank_label="Selecione um vendedor"
                        disabled={!can('READ_ALL', 'DIRECT_PURCHASE')}
                        value={params.seller_id}
                        onChange={(e: any) =>
                            setParams({ ...params, seller_id: e.target.value })
                        }
                        options={sellers}
                        value_key="id"
                        label_key="name"
                    />
                </div>

                <div className="directSalesOrderItemInputsSelects">
                    <Select
                        style={{}}
                        description="Status"
                        value_key="value"
                        label_key="label"
                        blank_label="Selecione um status"
                        value={params.status}
                        options={statuses}
                        onChange={(e: any) =>
                            setParams({ ...params, status: e.target.value })
                        }
                    ></Select>
                </div>

                <div className="directSalesOrderItemInputsSelects">
                    <Select
                        style={{}}
                        description="Rota"
                        blank_label="Selecione uma rota"
                        onChange={(e: any) =>
                            setParams({ ...params, route_id: e.target.value })
                        }
                        options={routes}
                        value_key="id"
                        label_key="description"
                    />
                </div>

                <div className="directSalesOrderItemInputsSelects">
                    <Select
                        style={{}}
                        description="Local de retirada"
                        blank_label="Selecione um local de retirada"
                        onChange={(e: any) =>
                            setParams({ ...params, id_local: e.target.value })
                        }
                        options={locales}
                        value_key="id"
                        label_key="descricao"
                    />
                </div>

                <div className="directSalesOrderItemInputsSelects">
                    <Select
                        style={{}}
                        description="Tipo de Entrega"
                        blank_label="Escolha o tipo"
                        onChange={(e: any) =>
                            setParams({
                                ...params,
                                delivery_type: e.target.value,
                            })
                        }
                        options={[
                            { name: 'Entrega', value: 'ENTREGA' },
                            { name: 'Retirada', value: 'RETIRADA' },
                        ]}
                        value_key="value"
                        label_key="name"
                    />
                </div>
                <div className="directSalesOrderItemInputsSelects">
                    <Select
                        style={{}}
                        value={params.integration_field}
                        description="Integração Externa"
                        blank_label="Selecione"
                        onChange={(e: any) =>
                            setParams({
                                ...params,
                                integration_field: e.target.value,
                            })
                        }
                        // options={[{ name: 'Sem Field Control', value: 'NOT_FIELD_CONTROL' }, { name: 'Field Control', value: 'FIELD_CONTROL' }, { name: 'Compra Direta', value: 'DIRECT_PURCHASE' }]}
                        // options={[{ name: 'Integrado', value: 'NOT_FIELD_CONTROL' }, { name: 'Não integrado', value: 'FIELD_CONTROL' }]}
                        options={[
                            {
                                name: 'Integrado',
                                value: 'INTEGRATION',
                            },
                            {
                                name: 'Não integrado',
                                value: 'NOT_INTEGRATION',
                            },
                            {
                                name: 'Field Control',
                                value: 'FIELD_CONTROL',
                            },
                            {
                                name: 'Sem Field Control',
                                value: 'NOT_FIELD_CONTROL',
                            },
                        ]}
                        //entegrado -- nao entegrado
                        value_key="value"
                        label_key="name"
                    />
                </div>
                <div className="directSalesOrderItemInputsSelects">
                    <Select
                        style={{}}
                        disabled={params.integration_field !== 'FIELD_CONTROL'}
                        value={params.status_control}
                        description="Status Field Control "
                        blank_label="Selecione"
                        onChange={(e: any) =>
                            setParams({
                                ...params,
                                status_control: e.target.value,
                            })
                        }
                        // options={[{ name: 'Integrado', value: 'INTEGRATED' }, { name: 'Com Erro', value: 'FAILED' }]}//!nao entegrado
                        options={[
                            {
                                name: 'Integrado com sucesso',
                                value: 'INTEGRATED',
                            },
                            {
                                name: 'Com Erro',
                                value: 'FAILED',
                            },
                        ]}
                        value_key="value"
                        label_key="name"
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', gap: 15 }}>
                    <CheckBoxCustom
                        value={params.payment}
                        // change={(e, stats) => (e, stats)}
                        change={(e, stats) =>
                            stats
                                ? setParams({
                                      ...params,
                                      paymentStatus: 'PAGO',
                                  })
                                : setParams({ ...params, paymentStatus: null })
                        }
                        checked={params.payment}
                        description={'Com Pix?'}
                    ></CheckBoxCustom>
                </div>
            </div>
            {
                //    </FilterComponent>
            }
            {/* <BaseModal
                // data={detailsOrder}
                title={`Gerar Planilha`}
                isVisible={isExcelModalVisible}
                onCloseCallback={() => { setIsExcelModalVisible(false) }}
                modalBodyStyle={{ 'height': 'unset', 'overflowY': 'visible' }}
                children={<DownloadExcelModal
                    // routes={routes}
                    data={{}}
                    // onUpdate={() => search()}
                    onCloseCallback={() => setIsExcelModalVisible(false)}
                />
                }
            /> */}
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    routes: state.routes.routes,
    sellers: state.salesmen,
    user: state.loggedUser,
})

const mapDispatchToProps = (dispatch: any) => ({
    load_sellers: () => dispatch(loadSalesmen()),
    load_routes: (provider_id: number) => dispatch(load_routes(provider_id)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DirectOrdersFilterComponent)
