import DateService from 'services/DateService'

const endDate: Date = new Date()
const initialDate: Date = DateService.removeDaysToDate(new Date(endDate), 10)

const initialState = {
    requests: {
        page: 1,
        dataIni: DateService.formatToRequest(initialDate),
        status: '',
        cliente: '',
        vendedor: '',
        numerocotacao: null,
        just_mine: true,
        active_only: 0,
        uf: '',
    },
}

export const SET_FILTERS = 'SET_FILTERS'

export default function route(state = initialState, action: any) {
    if (action.type === SET_FILTERS) {
        return { ...state, requests: action.filters }
    }
    return state
}

export const set_requests_filters = (params: any) => (dispatch: any) => {
    dispatch(set_requests(params))
}

const set_requests = (filters: any) => {
    return { type: SET_FILTERS, filters }
}
