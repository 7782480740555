import HttpClient from './http.client'

interface RepresentativeServiceParams {
    params: Params
}

type Params = {
    attending?: boolean
    page?: number
    per_page?: number
    vendedor_id?: string
}

export class RepresentativesServiceV3 {
    static getClientsRepresentative = ({
        params,
    }: RepresentativeServiceParams): Promise<any> => {
        const { vendedor_id, attending = true, page, per_page = 10 } = params
        const url = `/providers/sellers/${vendedor_id}/clients/available?attending=${attending}&page=${page}&per_page=${per_page}`

        return HttpClient.get(url).then((response) => {
            return response
        })
    }
}
