import { FC } from 'react'
import styles from './index.module.scss'

interface CardInfoProps {
    title: string
    quantity: any
    variant: 'orange' | 'green' | 'blue' | 'purple' | 'gray' | 'black' | string
}

const CardInfo: FC<CardInfoProps> = ({ title, quantity, variant }) => {
    return (
        <div className={`${styles.statusCard} ${styles[variant]}`}>
            <span className={styles.title}>{title}</span>
            <span className={styles.quantity}>({quantity})</span>
        </div>
    )
}

export default CardInfo
