import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti'
import './index.scss'

interface ArrowsOrderbyProps {
    sortState: string // '' | 'asc' | 'desc'
}

const ArrowsOrderby = ({ sortState }: ArrowsOrderbyProps) => {
    return (
        <div className="arrows-container">
            {sortState === 'asc' && (
                <div className="arrow-icon">
                    <TiArrowSortedUp />
                </div>
            )}
            {sortState === 'desc' && (
                <div className="arrow-icon">
                    <TiArrowSortedDown />
                </div>
            )}
            {!sortState && (
                <div className="arrow-icon">
                    <div className="dualIcon">
                        <div className="iconUp">
                            <TiArrowSortedUp />
                        </div>
                        <div className="iconDown">
                            <TiArrowSortedDown />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ArrowsOrderby
