import { constants } from 'buffer'
import Constants from '../constants'
import { Representative } from '../interfaces/representative'
import HttpClient from './HttpClient'

const STATUS_ACTIVE = '1'

export default class RepresentativeService {
    static parse = (representativeFromApi): Representative => ({
        id: representativeFromApi.usu_id,
        name: representativeFromApi.usu_nome,
        login: representativeFromApi.usu_login,
        email: representativeFromApi.usu_email,
        accept_integration: representativeFromApi.accept_integration,
        phone: representativeFromApi.usu_telefone
            ? representativeFromApi.usu_telefone
            : '',
        status:
            representativeFromApi.usu_status &&
            representativeFromApi.usu_status === STATUS_ACTIVE,
        cpf: representativeFromApi?.usu_cpf,
        profile_id: representativeFromApi?.pfl_id,
        pfl_descricao: representativeFromApi?.pfl_descricao,
        group_id: representativeFromApi?.group_id,
        password: representativeFromApi?.usu_senha,
        isMaster: representativeFromApi.usu_vendedor === '0',
        isSupervisor: representativeFromApi.usu_supervisor === 1,
        isSalesman:
            representativeFromApi.usu_vendedor === '1' &&
            (representativeFromApi.usu_supervisor === 0 ||
                representativeFromApi.usu_supervisor == null),
        isSalesmanPlus: representativeFromApi.usu_vendedor_plus === 1,
        minimumBilling: representativeFromApi.usu_fatura_min,
        ddd: representativeFromApi.usu_ddd,
        participation: representativeFromApi.participation || 0,
        count_vendedor: representativeFromApi.count_vendedor || 0,
    })

    static getAll = (params: any): Promise<any> =>
        HttpClient.get('getvendedores', params).then((response) => {
            const representatives: Representative[] = []
            response.vendedores.data.forEach((representative: Representative) =>
                representatives.push(
                    RepresentativeService.parse(representative)
                )
            )
            response.vendedores.data = representatives
            return {
                totalMaster: response.countMaster.count_master,
                totalSupervisors: response.countSupervisores.count_supervisores,
                totalSalesmen: response.countVendedores.count_vendedores,
                list: response.vendedores,
            }
        })
    static get = (path: any): Promise<any> =>
        HttpClient.get(path).then((response) => {
            const representatives: Representative[] = []
            response.vendedores.data.forEach((representative: Representative) =>
                representatives.push(
                    RepresentativeService.parse(representative)
                )
            )
            response.vendedores.data = representatives
            return {
                totalMaster: response.countMaster.count_master,
                totalSupervisors: response.countSupervisores.count_supervisores,
                totalSalesmen: response.countVendedores.count_vendedores,
                list: response.vendedores,
            }
        })

    static getSupervisors = (): Promise<any> =>
        HttpClient.get(
            `${Constants.URL_API_REPRESENTATIVES}?supervisores=1`
        ).then((response) => {
            const representatives: Representative[] = []
            response.vendedores.forEach((representative: Representative) =>
                representatives.push(
                    RepresentativeService.parse(representative)
                )
            )
            return representatives
        })

    static getById = (representativeId: string): Promise<Representative> =>
        HttpClient.get(`/v2/sellers/${representativeId}`).then((response) =>
            RepresentativeService.parse(response.data)
        )

    static updateStatus = (representativeId): Promise<any> =>
        HttpClient.post(Constants.URL_API_REPRESENTATIVE_CHANGE_STATUS, {
            vendedor_id: representativeId,
        })

    static toggle_integration = (representativeId): Promise<any> =>
        HttpClient.patch(`/v2/bm/sellers/${representativeId}/toggle`, {})

    static add = (representativeData): Promise<any> =>
        HttpClient.post(`/inserirvendedor`, representativeData)
    static addSeller = (seller, client_id): Promise<any> =>
        HttpClient.patch(
            `${Constants.URL_V3_API}/providers/${seller}/clients/activate`,
            { client_id }
        )

    static edit = (representativeData): Promise<any> =>
        HttpClient.post(`/atualizarvendedor`, representativeData)

    static remove = (representativeId): Promise<any> =>
        HttpClient.post(`/deletarvendedor`, { vendedor_id: representativeId })
}
