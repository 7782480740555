import './index.scss'

interface ParticipationBadgeProps {
    participation: number // Espera um número entre 0 e 1
}

export const ParticipationBadge: React.FC<ParticipationBadgeProps> = ({
    participation,
}) => {
    const getParticipationClass = (participation) => {
        if (participation <= 0.3) {
            return 'low'
        } else if (participation > 0.7) {
            return 'high'
        } else {
            return 'medium'
        }
    }

    return (
        <span
            className={[
                'participationBadge',
                getParticipationClass(participation),
            ].join(' ')}
        >
            {(participation * 100).toFixed(2)}%
        </span>
    )
}
